.survey-settings-wrapper {
	padding: 0 15px;
}

.survey-category-head {
  padding: 15px 20px;
  border-bottom: 2px solid $border-color;

  @media screen and (min-width: 1200px) {
    padding: 20px;
  }
}

.survey-category-header {
  @include fontSize(24px);
  font-weight: $regular;
  margin: 0;

  @media screen and (min-width: 1200px) {
    @include fontSize(18px);
  }
}

.survey-category-list {
	@include clear-list();

  .survey-category-list-item-container {
    border-bottom: 1px solid $border-color;
    padding: 0 15px;

    .category-title {
      line-height: 18px;
    }

    .category-add-btn {

      .circled-icon {
      }

      svg {
        width: 10px;
        height: 10px;
        fill: #000;
      }
    }
  }

	.survey-category-list-item {
    .survey-category-list {
      border-left: 10px solid $border-color;
    }

	}
}

.survey-edit-question {
  flex: 0 0 50%;
  padding: 15px;

  .delete-survey-question-btn {
    transition: transform 0.3s;
  }

  &.marked-for-delete {
    .delete-survey-question-btn {
      transform: rotate(45deg);
    }

    .inner {
      border-top-color: $red;
    }
  }

  .inner {
    position: relative;
    padding: 15px;
    background: white;
    border:1px solid $border-color;
    border-top-width: 5px;
    transition: border-color 0.3s;
  }
}

.survey-questions-container {
  padding: 15px;
}

.survey-edit-question-heading {
  margin: 0;
  font-weight: $bold;
  @include fontSize(18px);
  margin-bottom: 20px;
}

.add-new-survey-question {
  flex: 0 0 50%;
  padding: 15px;
  height: 303px;
  .inner {
    height: 100%;
    background: $neutral-grey;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.survey-content-wrapper {
  position: relative;
	max-width: 58rem;
	flex: 1 1 auto;
	margin: 0 auto;
  z-index: 1;

	.survey-results-wrapper & {
		max-width: 95rem;
	}
}

.survey-container {
  min-height: 100vh;
  background: #2584c4 url('/img/login-bg.jpg') no-repeat 100% center;
  background-size: cover;
  background-attachment: fixed;
  color: #fff;
  position: relative;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.4);
  }

  &.survey-answer-container {
    // padding: 10rem 0 2rem;
		//
    // @media only screen and (min-width:1024px) {
    //   display: flex;
    //   align-items: center;
    //   justify-content: center;
    //   padding-top: 15px;
    // }
		.progressbar-wrapper {
			position: fixed;
			top: 0;
			left: 0;
			right: 0;

			@media screen and (min-width: 768px) {
				position: static;
			}
		}

		.progressbar {
		  order: 0;
		  margin: 0;
		  background: rgba(255, 255, 255, 0.7);
			height: 5px;

		  @media screen and (min-width: 768px) {
		    margin: 0;
		    height: 8px;
				border-radius: 5px;
		  }

		  .progress {
				@media screen and (min-width: 768px) {
					border-radius: 5px;
				}

		    &.progress-survey {
		      background: $navigio-blue;
		    }
		  }
		}

    .logo-container {
      position: absolute;
      top: 2rem;
			width: 100%;

			@media (min-width: 768px) {
				top: 3rem;
			}
    }

		.logo-wrapper {
			width: 107px;

			@media (min-width: 768px) {

			}
		}
  }

  .survey-intro-description {
    margin: 0 0 2em 0;
    @include fontSize(16px);
    font-weight: $regular;
    color: $neutral-grey;
  }

  .survey-intro-box {
    width: 100%;
    max-width: 600px;
    text-align: center;
  }

  .question {
    // width: 100%;
    // max-width: 450px;
    margin-bottom: 2em;
    // margin: 0 auto 2em auto;
  }

  .survey-answer-value {
    margin-bottom: 10px;
  }

  .survey-btn-container {
    width: 100%;
    margin: 0 auto;
    text-align: center;
  }

  .survey-answer-freetext{

    input, label, textarea {
      color: white;

      @include placeholder-color(#ccc)
    }
    textarea {
      height: auto;
    }
  }

  .survey-answer-value {
    display: flex;
    justify-content: space-between;

    .question-value-btn {
      color: white;
			width: 29px;
      height: 29px;

			@media only screen and (min-width:480px) {
				width: 35px;
				height: 35px;
			}

      &:hover {
        background: rgba(255, 255, 255, 0.3);
      }

      &.active {
        background: white;
        color: $navigio-blue;
      }
    }
  }
}

.client-logo-wrapper {
	width: 160px;
	padding: 1.5rem;

	@media screen and (min-width: 768px) {
		position: fixed;
		bottom: 0;
		right: 0;
		margin: 0;
	}
}

.client-logo {
	display: block;
	max-width: 100%;
  height: auto;
	max-height: 60px;
	margin: auto;

	@media screen and (min-width: 768px) {
		margin-right: 0;
	}
}

.survey-intro-heading {
	margin: 0 0 0.5em 0;
	font-weight: $medium;
	@include fontSize(22px);
}

.survey-results-heading {
	@include fontSize(40px);
	font-weight: $medium;
}

.survey-results-heading-lg {
	@include fontSize(28px);
	font-weight: $medium;
	margin: 0;
}

.survey-results-heading-md {
	@include fontSize(22px);
	font-weight: $medium;
}

.chart-heading-lg {
	@include fontSize(20px);
	font-weight: $medium;
	margin: 0;
}

.chart-heading {
	@include fontSize(18px);
	font-weight: $medium;
	margin: 0;
	min-height: 85px;
}

.question-heading-question {
	@include fontSize(14px);
	font-weight: 100;
	position: relative;
	display: inline-block;
	padding:0 15px;
	margin: 0;
	font-style: italic;

	&:before, &:after {
		content: "";
		position: absolute;
		width: 75px;
		background: rgba(255, 255, 255, 0.5);
		height: 1px;
		top: 58%;
		transform: translateY(-58%);
	}

	&:before {
		left: -75px;
	}

	&:after {
		right: -75px;
	}
}

.question-text {
	font-weight: $medium;
	margin-top: 0.5em;
	@include fontSize(18px);
}

.survey-next-btn {
  background: rgba(255, 255, 255, 0.1);
  line-height: initial;
  border: 1px #fff solid;
  color: #fff;
  text-decoration: none;

  span {
    line-height: 16px;
  }

  &:hover {
    background: #fff;
    color: #000;

    svg {
      fill: #000;
    }
  }

  svg {
    fill: #fff;
  }
}

// Results

.survey-results-wrapper {
	color: #fff;
	background-color: $deep-blue;
}

.survey-results-header {
	position: relative;
	height: 100vh;
	padding: 15px;
	background: $deep-blue url(/img/login-bg.jpg) no-repeat 100%;
	background-size: cover;

	&-inner {
		position: relative;
		z-index: 1;
	}

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
		background: -moz-linear-gradient(top, rgba(125,185,232,0) 0%, rgba(32,42,54,1) 100%);
		background: -webkit-linear-gradient(top, rgba(125,185,232,0) 0%,rgba(32,42,54,1) 100%);
		background: linear-gradient(to bottom, rgba(125,185,232,0) 0%,rgba(32,42,54,1) 100%);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#007db9e8', endColorstr='#202a36',GradientType=0 );
  }
}

.results-list-number {
	position: absolute;
	left: 0;
	width: 4rem;
	text-align: right;
}

.results-bar {
	width: 100%;
}

.results-bar-md {
	&.results-bar,
	.results-bar {
		height: 8px;
		border-radius: 8px;
	}
}

.results-bar-sm {
	&.results-bar,
	.results-bar {
		height: 6px;
		border-radius: 6px;
	}
}

.results-bar-body {
	background: #fff;
}

.indicator {
	display: inline-block;
	border-width: 1px;
	border-style: solid;
	width: 20px;
	height: 20px;
	border-radius: 50%;
}

.answer-others,
.answer-mine {
	border-color: #fff;
}

.answer-others {
	background: $navigio-blue;
}

.answer-mine {
	background: $orange;
}

.survey-heading-lg {
	@include fontSize(25px);
	font-weight: $medium;
}

.survey-heading-md {
	@include fontSize(18px);
	font-weight: $medium;
}

.survey-heading-sm {
	@include fontSize(16px);
	font-weight: $medium;
}

.survey-dont-know-checkbox {
	margin-right: 8px;
}

.img-upload {
	position: relative;

	.dropdown-more-wrapper {
		position: absolute;
		top: 0;
		right: 0;
	}
}

.settings-col-1 {
	@media (min-width: 480px) {
		width: 70%;
	}

	@media (min-width: 768px) {
		width: 60%;
	}
}

.settings-col-2 {
	@media (min-width: 480px) {
		width: 30%;
	}

	@media (min-width: 768px) {
		width: 40%;
	}
}

.two-col-chart {
	// flex: 0 0 50%;
	float: left;
	width: 50%;

	&:nth-child(odd) {
		padding-right: 1rem;
		clear: left;
	}

	&:nth-child(even) {
		padding-left: 1rem;
	}
}

.two-col-chart-sm {
	min-height: 200px;
}

.two-col-chart-lg {
	min-height: 230px;
}

.two-col-chart-sm-noXvalues {
	min-height: 200px - 25px;
}

.clearfix {
	&:after {
		content: '';
		display: table;
		clear: both;
	}
}

.height-2bars {
	height: 100px;
}

.height-2bars-noXvalues {
	height: 100px - 25px;
}

.responserate-wrapper {
  position: relative;
	margin: 0 auto;
}

.survey-privacy-box{
	margin-bottom: 30px;
}