// Header search

.header-search {
  @include placeholder-color(#fff);
  background: $dark-blue;
  color: #fff;
  font-weight: $medium;
  border: none;
  border-radius: $border-radius;
  padding: 12px 13px 13px 42px;
  transition: all 0.2s ease-in-out;

  &:focus {
    outline: none;
    background: #fff;
    color: $black;
    border-radius: $border-radius $border-radius 0 0;

    & + .label-inside-search .search-icon {
      fill: $black;
    }
  }
}

.search-icon {
  width: 23px;
  height: 20px;
  fill: #fff;
  transition: all 0.2s ease-in-out;
}

.search-results-wrapper {
  display: none;
  position: absolute;
  top: 100%;
  right: 20px;
  left: 20px;
  padding-top: 20px;
  background: $grey-base;
  z-index: 1;
  box-shadow: 0px 5px 16px rgba(0, 0, 0, 0.2);
  border-top: 1px solid $border-color;
  border-radius: 0 0 3px 3px;
  max-height: 400px;
  overflow-x: auto;
  z-index: 2;

  @media screen and (min-width: 1200px) {
    max-height: none;
  }
}

.search-results {
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 1200px) {
    flex-direction: row;
  }

  .arrow-right-icon {
    fill: $grey-blue;
  }
}

.search-results-first {
  flex: 1 1 auto;

  @media screen and (min-width: 1200px) {
    width: 33.33%;
  }

  @media screen and (min-width: 1400px) {
    width: 54%;
  }
}

.search-results-first,
.search-results-middle {
  margin: 0 2rem 1rem;
}

.search-results-last {
  margin: 0 20px;

}

.search-results-middle,
.search-results-last {

  @media screen and (min-width: 1200px) {
    width: 33.33%;
    margin: 0 20px 0 0;
  }

  @media screen and (min-width: 1400px) {
    width: 28%;
  }
}

.col-stretched {
  flex-grow: 1;
  margin-left: 12px;
}

.search-results-footer {
  padding: 10px 20px;
}

.show-all {
  display: block;
  padding: 10px 0;
  text-align: center;
  color: $navigio-blue;
  text-decoration: none;

  &:hover {
    background: $neutral-grey;
  }
}
