.file-modal-wrapper {
  margin: 0;
  padding: 0;

  @media screen and (min-width: 1024px) {
    height: 80vh;
  }
}
.file-modal-main {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  // overflow: hidden;

  @media screen and (min-width: 1024px) {
    flex-direction: row;
  }
}

.file-modal-side {
  padding: 20px;
  background: $grey-base;

  @media screen and (min-width: 1024px) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 40%;
  }

  @media screen and (min-width: 1440px) {
    width: 33.33%;
  }

  &-footer {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.file-modal-content {
  position: relative;
  flex: 1 1 auto;
  height: 100%;
}

.file-modal-body {
  height: 100%;
  overflow-y: scroll;
  padding: 30px 30px 97px;
}

.version-list {
  @include clear-list();
  margin-bottom: 1rem;

  li {
    margin-bottom: 0.5rem;
  }
}

.file-preview:before {
  content: '';
  display: block;
  position: absolute;
  height: 100%;
  width: 100%;
  background: -moz-linear-gradient(top, rgba(0,0,0,0) 36%, rgba(0,0,0,0) 58%, rgba(0,0,0,1) 100%);
  background: -webkit-linear-gradient(top, rgba(0,0,0,0) 36%,rgba(0,0,0,0) 58%,rgba(0,0,0,1) 100%);
  background: linear-gradient(to bottom, rgba(0,0,0,0) 36%,rgba(0,0,0,0) 58%,rgba(0,0,0,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#000000',GradientType=0 );
}

.file-preview {
  position: relative;
  height: 170px;
  border: 1px solid $border-color;
  background-color: $grey-base;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url('https://upload.wikimedia.org/wikipedia/commons/b/bd/Tampa_FL_Sulphur_Springs_Tower_tall_pano02.jpg');

  @media screen and (min-width: 1024px) {
    height: 250px;
  }
}

.file-preview-btn {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  margin-bottom: 2rem;
  padding: 0.5rem 1.5rem;
  border-radius: 30px;
  background: rgba(0,0,0,0.2);
  color: #fff;
  white-space: nowrap;
}

.file-comments-wrapper {
  position: relative;
  overflow: scroll;
}

.file-comment-item {
  display: flex;
  padding: 1.0rem 0;
  border-bottom: 1px solid $border-color;
}

.file-comment-footer {
  position: absolute;
  right: 0;
  bottom: 0px;
  left: 0;
  width: 100%;
  padding: 15px 30px 30px;
  background: #fff;
}

.file-comment-footer-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 10px;
  border: 1px solid $border-color;

  input {
    padding: 0;
    border: 0;
  }
}

.bordered-header {
  border-bottom: 1px solid $black;
}

.file-article {
  width: 100%;
}

.file-comment-name-row {
  width: 100%;
}

.file-trash-button {
  float: right;
}