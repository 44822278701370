.page {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  // height: 100%;
}

.content-wrapper {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;

  @media screen and (min-width: 1024px) {
    flex-direction: row;
    padding: 0 10px;
    // padding: 0 10px 0 0;
  }

  @media screen and (min-width: 1200px) {
    padding: 0 50px;
    // padding: 0 50px 0 0;
  }
}

.content-wrapper-sidebar {
  @media screen and (min-width: 1024px) {
    padding: 0 10px 0 0;
  }

  @media screen and (min-width: 1200px) {
    padding: 0 50px 0 0;
  }
}

.content-main,
.content-full {
  flex: 1 1 auto;
  padding-bottom: 140px;
}

@media screen and (min-width: 1024px) {
  .content-main {
    flex: 1 1 auto;
  }

  .content-sidebar {
    flex-shrink: 0;
    width: 250px;
  }
}

@media screen and (min-width: 1200px) {
  .content-sidebar {
    width: 300px;
  }
}

.content-main {
  background: #fff;
  border-right: 1px solid $border-color;
}

.content-sidebar {
  border-right: 1px solid $border-color;
}

.content-single {
  border-left: 1px solid $border-color;
}
