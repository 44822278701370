.svg-wrapper {
  line-height: 1;
}

.circled-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  border-width: 1px;
  border-style: solid;
  border-radius: 50%;
  overflow: hidden;

  &-filled {
    border-color: transparent;
  }

  &-bold {
    border-width: 2px;
  }

  &-md {
    width: 28px;
    height: 28px;

    .checkmark-icon {
      width: 100%;
      height: 14px;
    }
  }

  &-lg {
    width: 60px;
    height: 60px;
  }
}

.add-icon {
  line-height: 1;

  &-md {
    @include fontSize(22px);
  }

  &-lg {
    @include fontSize(32px);
  }
}

.arrow-right-icon {
  width: 10px;
  height: 23px;
}

.arrow-left-icon {
  width: 10px;
  height: 23px;
}

.logo-large {
  width: 100%;
  height: 90px;
}

.logo-medium {
  width: 100%;
  height: 45px;

  @media screen and (min-width: 768px) {
    height: 55px;
  }
}

.logo-small {
  width: 100%;
  height: 30px;
}

.checkmark-icon-sm {
  width: 12px;
  height: 12px;
}

.select-dropdown .search-icon {
  fill: $label-grey;
  width: 15px;
  height: 15px
}

.close-icon {
  &.close-icon-black {
    fill: $black;
  }

  .icon-btn-xs & {
    width: 8px;
    height: 8px;
  }
}

.sort-arrow-icon {
  position: absolute;
  right: 0;
  width: 8px;
  height: 12px;

  &-up {
    top: 0;
  }

  &-down {
    top: 7px;
  }
}

.cross-icon {
  fill: #fff;
  width: 20px;
  height: 20px;
  transform: rotate(45deg);
  transition: transform 150ms;

  &-sm {
    width: 11px;
    height: 11px;
  }
}
