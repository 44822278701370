.container {
  // padding: 0 15px;

  @media screen and (min-width: 1024px) {
    padding: 0 10px;
  }

  @media screen and (min-width: 1200px) {
    padding: 0 50px;
  }
}

.relative {
  position: relative;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
}

.ellipsis-nowrap {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.hidden {
  position: absolute;
  left: -9999px;
}

.h100 {
  height: 100%;
}

.w100 {
  width: 100%;
}

.w50 {
  width: 50%;
}

.w60 {
  width: 60%;
}

.w40 {
  width: 40%;
}
