.progress-circle {
    width: 100%;
}

.progress-circle-progress {
    stroke: $navigio-blue;
    transition: stroke-dashoffset .75s ease 0s;
}

.progress-circle-trail {
    stroke: transparentize($navigio-blue, 0.9);
}

.progress-circle-text {
    @include fontSize(20px);
    font-weight: $medium;
    display: block;
    fill: #fff;

    .percentage {
        @include fontSize(10px);
    }
}
