#overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9998;
  background: rgba(0,0,0,0.5);
}

#modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9999;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.modal-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  // display: flex;
  // flex-direction: column;
  padding: 30px 15px;
  background: #fff;
  // transform: translate(0, -100%);
  transform: translate(0, 0);
  // transition: transform 0.3s 1s ease-in-out;

  .modal-in & {
    transform: translate(0, 0);
  }

  @media screen and (min-width: 1024px) {
    left: 50%;
    width: 600px;
    padding: 30px;
    // transform: translate(-50%, -100%);
    transform: translate(-50%, 100px);

    .modal-in & {
      transform: translate(-50%, 100px);
    }
  }
}

.modal-sm {
  @media screen and (min-width: 1024px) {
    width: 440px;
    margin: 130px auto 130px;
  }
}

.modal-lg {
  @media screen and (min-width: 1024px) {
    width: 700px;
    margin: 130px auto 130px;
  }
  @media screen and (min-width: 1440px) {
    width: 900px;
    margin: 130px auto 130px;
  }
}

.modal-profile {
  text-align: center;

  @media screen and (min-width: 1024px) {
    padding: 0;
  }

  .modal-header {
    padding: 30px 0 20px;
    border-bottom: 1px solid $border-color;

    @media screen and (min-width: 1024px) {
      padding-top: 90px;
    }
  }

  .modal-body {
    padding: 20px 0;

    @media screen and (min-width: 1024px) {
      @include fontSize(20px);
    }
  }

  .modal-footer {
    display: flex;
    flex-wrap: wrap;
    padding-top: 20px;
    border-top: 1px solid $border-color;

    @media screen and (min-width: 1024px) {
      @include fontSize(18px);
      padding-bottom: 20px;
    }

    &-col {
      width: 100%;
      line-height: 2.3;

      @media screen and (min-width: 500px) {
        width: 50%;
      }
    }
  }

  .fa {
    margin-right: 5px;
  }

  .fa-phone {
    font-size: 105%;
    vertical-align: middle;
  }
}
