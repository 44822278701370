// Flex variation from Bootrap
//
// Custom styles for additional flex alignment options.

@mixin _assert-ascending($map, $map-name) {
  $prev-key: null;
  $prev-num: null;
  @each $key, $num in $map {
    @if $prev-num == null {
      // Do nothing
    } @else if not comparable($prev-num, $num) {
      @warn "Potentially invalid value for #{$map-name}: This map must be in ascending order, but key '#{$key}' has value #{$num} whose unit makes it incomparable to #{$prev-num}, the value of the previous key '#{$prev-key}' !";
    } @else if $prev-num >= $num {
      @warn "Invalid value for #{$map-name}: This map must be in ascending order, but key '#{$key}' has value #{$num} which isn't greater than #{$prev-num}, the value of the previous key '#{$prev-key}' !";
    }
    $prev-key: $key;
    $prev-num: $num;
  }
}

@mixin _assert-starts-at-zero($map) {
  $values: map-values($map);
  $first-value: nth($values, 1);
  @if $first-value != 0 {
    @warn "First breakpoint in `$grid-breakpoints` must start at 0, but starts at #{$first-value}.";
  }
}

@include _assert-ascending($grid-breakpoints, "$grid-breakpoints");
@include _assert-starts-at-zero($grid-breakpoints);

@function breakpoint-min($name, $breakpoints: $grid-breakpoints) {
  $min: map-get($breakpoints, $name);
  @return if($min != 0, $min, null);
}

@mixin media-breakpoint-up($name, $breakpoints: $grid-breakpoints) {
  $min: breakpoint-min($name, $breakpoints);
  @if $min {
    @media (min-width: $min) {
      @content;
    }
  } @else {
    @content;
  }
}

@function breakpoint-infix($name, $breakpoints: $grid-breakpoints) {
  @return if(breakpoint-min($name, $breakpoints) == null, "", "-#{$name}");
}

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    .flex#{$infix}-first     { order: -1; }
    .flex#{$infix}-last      { order: 1; }
    .flex#{$infix}-unordered { order: 0; }

    .flex#{$infix}-row            { flex-direction: row !important; }
    .flex#{$infix}-column         { flex-direction: column !important; }
    .flex#{$infix}-row-reverse    { flex-direction: row-reverse !important; }
    .flex#{$infix}-column-reverse { flex-direction: column-reverse !important; }

    .flex#{$infix}-wrap         { flex-wrap: wrap !important; }
    .flex#{$infix}-nowrap       { flex-wrap: nowrap !important; }
    .flex#{$infix}-wrap-reverse { flex-wrap: wrap-reverse !important; }

    .justify-content#{$infix}-start   { justify-content: flex-start !important; }
    .justify-content#{$infix}-end     { justify-content: flex-end !important; }
    .justify-content#{$infix}-center  { justify-content: center !important; }
    .justify-content#{$infix}-between { justify-content: space-between !important; }
    .justify-content#{$infix}-around  { justify-content: space-around !important; }

    .align-items#{$infix}-start    { align-items: flex-start !important; }
    .align-items#{$infix}-end      { align-items: flex-end !important; }
    .align-items#{$infix}-center   { align-items: center !important; }
    .align-items#{$infix}-baseline { align-items: baseline !important; }
    .align-items#{$infix}-stretch  { align-items: stretch !important; }

    .align-content#{$infix}-start   { align-content: flex-start !important; }
    .align-content#{$infix}-end     { align-content: flex-end !important; }
    .align-content#{$infix}-center  { align-content: center !important; }
    .align-content#{$infix}-between { align-content: space-between !important; }
    .align-content#{$infix}-around  { align-content: space-around !important; }
    .align-content#{$infix}-stretch { align-content: stretch !important; }

    .align-self#{$infix}-auto     { align-self: auto !important; }
    .align-self#{$infix}-start    { align-self: flex-start !important; }
    .align-self#{$infix}-end      { align-self: flex-end !important; }
    .align-self#{$infix}-center   { align-self: center !important; }
    .align-self#{$infix}-baseline { align-self: baseline !important; }
    .align-self#{$infix}-stretch  { align-self: stretch !important; }

    .flex#{$infix}-2 { flex: 2 2 auto !important; }
    .flex#{$infix}-1 { flex: 1 1 auto !important; }
    .flex#{$infix}-0 { flex: 0 0 auto !important; }
  }
}

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    .d#{$infix}-none         { display: none !important; }
    .d#{$infix}-inline       { display: inline !important; }
    .d#{$infix}-inline-block { display: inline-block !important; }
    .d#{$infix}-block        { display: block !important; }
    .d#{$infix}-table        { display: table !important; }
    .d#{$infix}-table-row    { display: table-row !important; }
    .d#{$infix}-table-cell   { display: table-cell !important; }
    .d#{$infix}-flex         { display: flex !important; }
    .d#{$infix}-inline-flex  { display: inline-flex !important; }
    
  }
}

.flex-grow {
  flex-grow: 1;
}
