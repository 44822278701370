.link-clean {
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.link-underline {
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
}

.link-clear {
  text-decoration: none;
  
  &:hover {
    text-decoration: none;
  }
}
