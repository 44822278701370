.form-header {
  margin-bottom: 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid $black;
}

.form-cta-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 40px;
}

.radio-row {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;

  @media screen and (min-width: 1024px) {
    flex-wrap: nowrap;
  }

  &-item {
    flex: 0 0 auto;
    margin-bottom: 15px;

    @media screen and (min-width: 1024px) {
      flex: 0 0 33%;
    }
  }
}

.radio-heading {
  @include fontSize(16px);
  font-weight: $regular;
  margin: 0 0 10px;
}


.arrowed-input {

  .arrow-icon {
    position: absolute;
    right: 8px;
    bottom: 8px;
    width: 18px;
    height: 24px;
    z-index: 1;
  }

  .arrow-down-icon {
    display: block;
  }

  .arrow-up-icon {
    display: none;
  }

  &.open {

    .arrow-down-icon {
      display: none;
    }

    .arrow-up-icon {
      display: block;
    }
  }
}


.form-message {
  @include fontSize(14px);
  margin-bottom: 20px;
  padding: 6px;
  text-align: center;

  &.info {
    background: $info;
  }

  &.alert {
    background: $alert;
  }

  &.error {
    background: $error;
  }
}

.form-alert {
  @include fontSize(13px);
  // padding: 3px 10px 0; not sure if I should remove this?

  .error & {
    color: $red;
  }

  .warning & {
    color: $orange;
  }
}

.form-alert-rounded {
  margin-bottom: 15px;
  padding: 5px;
  border-radius: $border-radius;
  text-align: center;
  background: #fff;
  color: $red;
}
