//http://www.dropzonejs.com/

.file-upload {
  padding: 10px;
  background: $neutral-grey;
}

.label-neutral {
  color: $label-grey;
}

.box {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  min-height: 270px;
  background: $neutral-grey;
  border: 1px solid $border-color;
  transition: background-color .15s linear;

  &.dz-drag-hover {
    background-color: #fff;
  }
}

.dz-preview {
  display: inline-block;
  max-width: 33%;
  padding: 10px;
}

.dz-size {
  display: none;
}

.dz-success-mark {
  display: none;
}

.dz-error-mark {
  display: none;
}

.file-input {
  width: 70%;

  .dz-max-files-reached & {
    display: none;
  }
}

  .box.is-uploading .box__input,
  .box.is-success .box__input,
  .box.is-error .box__input
  {
    visibility: hidden;
  }

  .box__uploading,
  .box__success,
  .box__error
  {
    display: none;
  }
  .box.is-uploading .box__uploading,
  .box.is-success .box__success,
  .box.is-error .box__error
  {
    display: block;
    position: absolute;
    top: 50%;
    right: 0;
    left: 0;

    transform: translateY( -50% );
  }
  .box__uploading
  {
    font-style: italic;
  }
  .box__success
  {
    animation: appear-from-inside .25s ease-in-out;
  }

  @keyframes appear-from-inside
  {
    from	{ transform: translateY( -50% ) scale( 0 ); }
    75%		{ transform: translateY( -50% ) scale( 1.1 ); }
    to		{ transform: translateY( -50% ) scale( 1 ); }
  }

  .box__restart
  {
    font-weight: 700;
  }
  .box__restart:focus,
  .box__restart:hover
  {
    color: #39bfd3;
  }

  .js .box__file
  {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }
  .js .box__file + label
  {
    max-width: 80%;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
    display: inline-block;
    overflow: hidden;
  }
  .js .box__file + label:hover strong,
  .box__file:focus + label strong,
  .box__file.has-focus + label strong
  {
    color: #39bfd3;
  }
  .js .box__file:focus + label,
  .js .box__file.has-focus + label
  {
    outline: 1px dotted #000;
    outline: -webkit-focus-ring-color auto 5px;
  }
  .js .box__file + label *
  {
    /* pointer-events: none; */ /* in case of FastClick lib use */
  }

  .no-js .box__file + label
  {
    display: none;
  }

  .no-js .box__button
  {
    display: block;
  }
