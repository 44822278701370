.dropdown-wrapper {

  &.open {
    .select-dropdown {
      display: block;
    }
  }
}

.dropdown-placeholder {
  @extend .input;
  position: relative;
  text-align: left;
  cursor: pointer;

  &:active {
    color: currentColor;
  }

  &:disabled {
    .arrow-icon {
      fill: graytext;
    }
  }

  &-text {
    display: block;
    width: 100%;
    padding-right: 30px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}


.select-dropdown {
  position: absolute;
  top: 100%;
  width: 100%;
  max-height: 200px;
  margin-top: -1px;
  margin-bottom: 10px;
  padding: 10px;
  box-shadow: 0 4px 10px 1px rgba(0,0,0,.2);
  border-radius: 3px;
  background: #fff;
  z-index: 2;
  display: none;
  overflow: auto;

  .select-option-btn {
    @extend .clear-btn;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 40px;
    line-height: 40px;
    padding: 0 11px;
    border-radius: 2px;

    .checkmark-icon {
      fill: transparent;
      width: 17px;
      height: 17px;
    }

    &:hover {
      background: $neutral-grey;
    }
  }

  .select-option-heading {
    .select-option-btn {
      line-height: 30px;
      height: 30px;
      pointer-events: none;
      color: gray;
      @include fontSize(14px);
    }
  }

  .sub-option {
    .select-option-btn {
      padding-left: 25px;
    }
  }
}

.select-option.selected {
  .select-option-btn {
    background: $neutral-grey;
    font-weight: $medium;
    color: $navigio-blue;
  }

  .checkmark-icon {
    fill: $navigio-blue;
  }
}


.dropdown-search {
  @extend .clear;
  padding: 0;
  transition: padding 200ms ease-in-out;

  &:focus,
  &.value {
    padding-top: 10px;
  }

  &:focus {
    border-color: $navigio-blue;
  }

  .btn-remove {
    z-index: 1;
  }
}

.selected-items {
  display: flex;
  flex-wrap: wrap;
  border-width: 0 0 1px;
  border-style: solid;
  border-color: $border-color;
  padding: 17px 0 7px;

  .error & {
    border-color: $red;
  }
}

.select-option.selected {
  position: relative;
  display: flex;
  align-items: center;
  margin-right: 10px;
  // padding-top: 10px;
  // height: $input-height;

  .select-value {
    padding-right: 20px;
    white-space: nowrap;
  }
}

.dropdown-add-user-cv {
  margin-top: 10px;
}

.dropdown-input {
  display: flex;
  align-items: center;
  flex: 1 0 auto;
  // height: $input-height;
  // padding-top: 10px;
}

.dropdown-search-input {
  @include placeholder-color($black);
  float: left;
  margin: 0;
  padding: 0;
  border: none;
  width: 100%;
}

.dropdown-search-options {
  position: absolute;
  top: 100%;
  width: 100%;
  max-height: 200px;
  padding: 10px;
  box-shadow: 0 4px 10px 1px rgba(0,0,0,.2);
  border-radius: 3px;
  background: #fff;
  z-index: 2;
  overflow: auto;

  .select-option {
    display: flex;
    flex-wrap: wrap;
    @media screen and (min-width: 1024px) {
      flex-wrap: nowrap;
    }

    &:hover,
    &.focus {
      background: $neutral-grey;
    }
  }

  .select-value {
    @extend .clear-btn;
    flex: 1 0 auto;
    display: flex;
    //justify-content: space-between;
    align-items: center;
    // width: 100%;
    height: 40px;
    padding: 0 11px;
    border-radius: 2px;


    &.email {
      @include fontSize(14px);
      flex: 0 0 auto;
      width: auto;
    }
  }
}

.new-addition {
  margin: -1px -30px 0;
  padding: 20px 30px;
  background: $menu-grey;
}
