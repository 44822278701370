.member-grid {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  @media screen and (min-width: 1024px) {
    flex-direction: row;
    margin: 0 -15px;
  }

  @media screen and (min-width: 1200px) {
    margin: 0 -20px;
  }
}

.member-grid-item {

  @media screen and (min-width: 1024px) {
    flex: 1 1 auto;
    width: 33.33%;
    padding: 15px;
  }
}

.member-grid-content {
  @media screen and (min-width: 1024px) {
    box-shadow: 0 1px 3px rgba(0,0,0,.14);
  }
}

.member-list-head {
  display: flex;
  align-items: baseline;
  padding: 1rem 15px;
  border-bottom: 1px solid $border-color;
  color: $text-grey;

  @media screen and (min-width: 1024px) {
    padding: 15px 20px;
    background: #fff;
    color: $black;
  }
}

.member-list-header {
  @include fontSize(12px);
  font-weight: $medium;
  text-transform: uppercase;
  margin: 0;

  @media screen and (min-width: 1024px) {
    @include fontSize(18px);
    font-weight: $regular;
    text-transform: none;
  }
}

.member-list-item {
  display: flex;
  align-items: center;
  padding: 10px 0 10px 15px;
  background: #fff;
  border-bottom: 1px solid $border-color;

  .dropdown-more-wrapper {
    visibility: hidden;
  }

  &:hover {
    .dropdown-more-wrapper {
      visibility: visible;
    }
  }

  &.rejected {
    .wrapper-link {
      opacity: 0.3;
    }
  }
}

.thumbnail-col {
  flex: 0 0 auto;
}

.member-wrapper {
  flex: 1 1 auto;
  margin-left: 12px;
  overflow: hidden; // for header search

  &-clean {
    margin-left: 0;
  }
}

.member-more-wrapper {
  color: $text-grey;
}

.member-more-wrapper,
.cta-wrapper {
  display: flex;
  align-items: center;
  margin-left: 12px;
}

.cta-wrapper {
  flex: 0 0 80px;
}

.icon-link {
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  width: 40px;
  height: 40px;

  .phone-icon,
  .profile-icon {
    fill: $navigio-blue;
  }

  .phone-icon {
    width: 20px;
    height: 22px;
  }

  .profile-icon {
    width: 15px;
    height: 20px;
  }
}
