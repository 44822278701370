.login-page {
  align-items: center;
  justify-content: center;
  background: $navigio-blue url('/img/login-bg-ocean.jpeg') no-repeat 75% center;
  background-size: cover;
}

.login-wrapper {
  width: 310px;
  overflow: hidden;

  .logo-wrapper {
    margin: 0 35px 50px;
  }

  @media screen and (min-width: 420px) {
    width: 410px;
  }
}

.login-forms {
  position: relative;
  display: flex;
  align-items: center;
  width: 200%;
  transition: transform 200ms ease-in-out;

  &.forgotten-visible {
    transform: translate3d(-50%, 0, 0);
  }
}

.login-form-wrapper {
  flex: 1 1 auto;
  width: 50%;
  padding: 20px;
}

.forgotPasswordThanks {
  opacity: 0;
  transition: opacity 200ms ease-in-out;
}

.toggle-login {
  &:active {
    color: #fff;
  }
}
