.page-header {
  position: relative;
  background: $navigio-blue;
  flex: 0 0 auto;

  @media screen and (min-width: 1024px) {
    padding: 0 50px;
  }
}

.page-header-top {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 15px 0;

  &-middle {
    position: relative;
    flex: 1 1 auto;
    padding: 0 20px;
  }

  &-right {
    display: flex;
  }

  @media screen and (min-width: 1024px) {
    padding: 15px 0;
  }
}

.page-header-middle {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 10px 0 0;

  @media screen and (min-width: 1024px) {
    flex-direction: row;
    align-items: center;
    padding: 0 0 15px;
  }
}

.page-header-middle-heading {
  display: flex;
  padding: 12px 15px;
  color: #fff;
  background: $dark-blue;

  @media screen and (min-width: 1024px) {
    flex: 0 0 100%;
    padding: 0;
    background: none;
  }

  .arrow-left-icon {
    fill: #fff;
    width: 14px;
    height: 30px;
    margin-right: 15px;
  }
}

.logo {
  display: block;


  .logo-svg {
    fill: #fff;
    width: 140px;
    height: 40px;

    @media screen and (min-width: 1024px) {
      width: 150px;
      height: 50px;
    }
  }
}

.header-search {
  display: none;

  @media screen and (min-width: 1024px) {
    display: block;
  }
}


.notifications-btn,
.profile-btn {
  @extend .clear-btn;
  display: block;
  width: 44px;
  height: 44px;
  cursor: pointer;

  &-inner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  &-icon {
    display: flex;
    justify-content: center;
    width: 30px;
    height: 30px;
    border: 2px solid #fff;
    border-radius: 50%;
    overflow: hidden;
  }

  .profile-icon,
  .bell-icon {
    width: 100%;
    fill: #fff;
  }

  .profile-icon {
    height: 30px;
    margin-bottom: -6px;
  }

  .bell-icon {
    height: 16px;
  }
}

.notifications-btn.active {
  text-decoration: none;

  .notifications-btn-icon {
    line-height: 1;
    background: $orange;
    color: darken($dark-blue, 30%);
    border-color: $orange;
  }
}

.notifications-btn-icon {
  align-items: center;
}

.profile-btn-icon {
  align-items: flex-end;
}

#confirm-close-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 10000;
  top: 0;
  right: 0;
  left: 0;
  padding: 10px 0;
  background: $info;
  text-align: center;
  line-height: 2.5;
  overflow: hidden;

  @media screen and (min-width: 725px) {
    flex-direction: row;
  }
}
