.notifications-indicator {
  @include fontSize(14px);
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 25px;
  height: 25px;
  padding: 0 8px;
  border-radius: 13px;
  font-weight: $medium;
  text-transform: uppercase;
  color: #fff;
  background: $orange;

  &-border {
    box-shadow: 0 0 0 3px $dark-grey;
  }

  &-new {
    @include fontSize(10px);
    padding: 0;
    width: 25px;
    font-weight: $medium;
    line-height: 25px;
  }

  &.status-good {
    background: $green !important;
  }

  &.status-warning {
    background: $warning !important;
  }

  &.status-danger {
    background: $red !important;
  }
}
