.pagination {
  text-align: center;
  padding: 2rem 0;

  .page-no {
    height: 30px;
    min-width: 30px;
    line-height: 30px;
    margin: 0 2px;
    text-align: center;
    background: $navigio-blue;
    color: #fff;
    border-radius: 50%;
    transition: all .2s ease-in-out;

    &:focus,
    &:hover {
      background: darken($navigio-blue, 8%);
    }

    &.current-page {
      display: inline-block;
      background: darken($navigio-blue, 8%);
      font-weight: $medium;
    }
  }
}
