.page-header-flyout {
  visibility: hidden;
  position: absolute;
  top: 60px;
  right: 15px;
  background: #fff;
  border-radius: 3px;
  z-index: 10;
  opacity: 0;
  box-shadow: 1px 1px 68px rgba(0, 0, 0, 0.19);
  transition: all 0.15s;

  @media screen and (min-width: 1024px) {
    top: 73px;
    right: 0;
  }

  &.open {
    opacity: 1;
    visibility: visible;
  }

  &.nav-profile {
    width: 260px;
    padding: 15px 15px 10px;
  }

  &.notifications-flyout {
    padding: 20px 20px 10px;
    right: 0;
    left: 0;

    @media screen and (min-width: 1024px) {
      width: 460px;
      left: auto;
    }
  }
}

.flyout-header {
  margin-bottom: 15px;
}

.flyout-footer {
  margin-top: 5px;
  padding-top: 5px;
  border-top: 1px solid $border-color;
}

.header-flyout-footer-link {
  text-align: center;
}
