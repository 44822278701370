.filter-wrapper {
  background: #fff;
  max-height: 50px;
  overflow: hidden;
  border-bottom: 1px solid $border-color;
  transition: max-height 300ms ease-in-out;

  &.open {
    max-height: 201px;
  }

  @media screen and (min-width: 1024px) {
    max-height: none;
    background: none;
    border: none;
  }
}

.filter {

  @media screen and (min-width: 1024px) {
    padding: 30px 0 0;
  }

  @media screen and (min-width: 1200px) {
    padding: 30px 0 30px 25px;
  }
}

.filter-progress {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid $border-color;

  @media screen and (min-width: 1024px) {
    display: block;
    border-bottom: none;
  }
}

.filter-btn {
  @include fontSize(15px);
  position: relative;
  width: 100%;
  height: 50px;
  padding: 0 15px;
  text-align: left;
  transition: color 0.15s ease-in-out;

  .btn-content {
    justify-content: space-between;
  }

  @media screen and (min-width: 1024px) {
    height: 35px;
    margin-bottom: 5px;
    padding: 0 20px;
    color: $grey-blue;
    border-width: 0 0 0 3px;
    border-style: solid;
    border-color: transparent;

    &.active {
      color: $navigio-blue;
    }

    &:hover {
      color: $black;
    }

    &-upcoming,
    &-delayed,
    &-done {
      &.active {
        font-weight: $medium;
      }
    }

    &-upcoming {
      border-color: $navigio-blue;

      &.active {
        color: $navigio-blue;
      }
    }

    &-delayed {
      border-color: $red;

      &.active {
        color: $red;
      }
    }

    &-done {
      border-color: $green;

      &.active {
        color: $green;
      }
    }
  }

  @media screen and (min-width: 1200px) {
    @include fontSize(18px);
    height: 45px;
    padding: 0 25px;
    border-width: 0 0 0 2px;
  }

  .arrow-icon {
    bottom: auto;
    right: 15px;
  }
}

@media screen and (max-width: 1024px) {

  .filter-btn-done {
    order: 1;
  }

  .filter-btn-upcoming {
    order: 2;
  }

  .filter-btn-delayed {
    order: 3;
  }

  .filter-btn-spec {
    &.filter-btn-done {

      .btn-content:before {
        border-color: $green;
      }

      &.active {
        .btn-content:before {
          background: $green;
        }

        .filter-count {
          background: none;
          color: $black;
        }
      }
    }

    &.filter-btn-delayed {
      .btn-content:before {
        border-color: $red;
      }

      &.active {
        .btn-content:before {
          background: $red;
        }

        .filter-count {
          background: none;
          color: $black;
        }
      }
    }

    &.filter-btn-upcoming {
      .btn-content:before {
        border-color: $navigio-blue;
      }

      &.active {
        .btn-content:before {
          background: $navigio-blue;
        }

        .filter-count {
          background: none;
          color: $black;
        }
      }
    }
  }
}

.filter-btn-spec {

  @media screen and (max-width: 1023px) {
    position: relative;
    height: 95px;
    background: #fff;
    text-transform: uppercase;

    &:after {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      height: 50px;
      width: 1px;
      margin-top: -25px;
      background-color: $border-color;
    }

    &:last-child:after {
      content: none;
    }

    .btn-content {
      @include fontSize(12px);
      position: relative;
      flex-direction: column;
      justify-content: flex-start;
      height: 100%;
      padding-top: 36px;
      color: $text-grey;
      font-weight: $medium;

      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 50%;
        width: 12px;
        height: 12px;
        margin: 14px 0 0 -6px;
        border-width: 1px;
        border-style: solid;
        border-radius: 50%;
      }
    }

    .filter-count {
      @include fontSize(20px);
      background: none;
      color: $black;
    }
  }
}

.filter-btn-text {
  white-space: nowrap;
  overflow: hidden;
}

.filter-count {
  @include fontSize(13px);
  display: block;
  flex: 0 0 auto;
  min-width: 28px;
  height: 28px;
  line-height: 28px;
  padding: 0 5px;
  border-radius: 50%;
  background: #dadcde;
  color: $darkest-grey;
  font-weight: $regular;
  text-align: center;

  @media screen and (min-width: 1200px) {
    @include fontSize(14px);
  }

  .filter-btn.active & {
    color: #fff;
  }

  .filter-btn.active &,
  .filter-btn-upcoming.active & {
    background: $navigio-blue;
  }

  .filter-btn-delayed.active & {
    background: $red;
  }

  .filter-btn-done.active & {
    background: $green;
  }
}
