.timeline-section {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  padding: 50px 0;

  @media screen and (min-width: 1024px) {
    align-items: center;
    padding: 40px 0;
  }
}

.timeline {
  @extend .clear-list;
  position: relative;
  padding: 0 15px 0 30px;

  @media screen and (min-width: 1024px) {
    padding: 0;
  }
}

.timeline-progress {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 44px;
  height: 44px;
  background: $grey-base;
  border-radius: 50%;

  .circled-icon {
    border-color: $border-color;
  }
}

.milestone {
  width: 100%;
}

.milestone-heading {
  @include fontSize(16px);
  font-weight: $regular;
  margin: 9px 70px 25px 25px;

  @media screen and (min-width: 1024px) {
    @include fontSize(18px);
    margin: 7px 0 20px;
    max-width: 455px;
  }
}

.milestone-events {
  position: relative;

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 8px;
    width: 16px;
    height: 16px;
    background-color: $border-color;
    border-radius: 50%;
    border: 5px solid $grey-base;
  }
}

.milestone-indicator {
  display: none;
  position: absolute;
  width: 20px;

  &-candidates {
    top: 10px;
    margin-right: 30px;
  }

  &-files {
    top: 10px;
    margin-right: 30px;
  }

  &-todo {
    top: 10px;
    margin-right: 30px;
  }

  .doc-icon,
  .checkmark-icon,
  .profile-icon {
    width: 100%;
    fill: $border-color;
  }

  .doc-icon {
    height: 17px;
  }

  .checkmark-icon {
    height: 18px;
  }

  .profile-icon {
    height: 20px;
  }

  @media screen and (min-width: 1024px) {
    display: block;
  }
}

.milestone-tasks {
  position: relative;
  margin-bottom: 25px;
  margin-left: 25px;
  padding: 14px 16px;
  background: #fff;
  box-shadow: 0 2px 2px #e8e8e8;

  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 14px;
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
  }

  @media screen and (min-width: 1024px) {
    width: 455px;
  }
}

.milestone-tasks-list-item {
  @include fontSize(14px);
  display: flex;
  align-items: center;
  margin-bottom: 12px;

  .checkmark-icon-bullet {
    fill: $border-color;
  }

  &-stript,
  &:last-child {
    margin-bottom: 0;
  }

  &.done {
    .checkmark-icon-bullet {
      fill: $green;
    }
  }
}

.milestone-summary {
  @include fontSize(14px);
  margin: 0 20px 0 0;
}

.milestone-start-date {
  @include fontSize(14px);
  position: absolute;
  top: 11px;
  right: 0;
  color: $text-grey;
  white-space: nowrap;
}

.checkmark-icon-bullet {
  margin-right: 5px;
}


.timeline-item {
  display: flex;
  position: relative;
  border-left: 2px solid $border-color;
  margin-left: -1px;

  .timeline-progress {
    right: 100%;
    margin-right: -21px;
  }

  .milestone-events {

    &:before {
      right: 100%;
      margin-right: -12px;
    }
  }

  @media screen and (min-width: 1024px) {

    &:nth-child(odd) {
      left: 50%;
      justify-content: flex-start;

      .milestone-indicator {
        right: 100%;

        &-candidates {
          margin-right: 30px;
        }

        &-files {
          margin-right: 30px;
        }

        &-todo {
          margin-right: 30px;
        }
      }

      .milestone-heading {
        margin-left: 40px;
      }

      .milestone-tasks {
        margin-left: 40px;

        &:before {
          right: 100%;
          border-right: 10px solid #e8e8e8;
        }

        &:after {
          top: 12px;
          right: 100%;
          border-right: 10px solid #fff;
        }
      }

      .milestone-start-date {
        right: 100%;
        margin-right: 41px;
      }
    }

    &:nth-child(even) {
      right: 50%;
      justify-content: flex-end;
      margin-left: -1px;
      border-right: 2px solid $border-color;
      border-left: none;
      margin-left: 0;

      .timeline-progress {
        left: 100%;
        margin-left: -21px;
      }

      .milestone-heading {
        margin-right: 40px;
        text-align: right;
      }

      .milestone-events {

        &:before {
          left: 100%;
          margin-left: -12px;
        }
      }

      .milestone-indicator {
        left: 100%;

        &-candidates {
          margin-left: 30px;
        }

        &-files {
          margin-left: 30px;
        }

        &-todo {
          margin-left: 30px;
        }
      }

      .milestone-tasks {
        margin-right: 40px;
        margin-left: 0;

        &:before {
          left: 100%;
          border-left: 10px solid #e8e8e8;
        }

        &:after {
          top: 12px;
          left: 100%;
          border-left: 10px solid #fff;
        }
      }

      .milestone-start-date {
        right: auto;
        left: 100%;
        margin-left: 41px;
      }
    }
  }
}

.timeline-item {
  &.done {
    border-color: $green;
    .timeline-progress {

      .circled-icon {
        border-color: $green;
      }

      .checkmark-icon {
        fill: $green;
      }
    }

    .milestone-events:before {
      background-color: $green;
    }

    .milestone-indicator {

      .doc-icon,
      .profile-icon,
      .checkmark-icon {
        fill: $green;
      }
    }
  }

  &.active {
    border-color: $navigio-blue;

    .timeline-progress {

      .circled-icon {
        border-color: $navigio-blue;
      }
    }

    .milestone-events:before {
      background-color: $navigio-blue;
    }

    .milestone-indicator {

      .doc-icon,
      .profile-icon,
      .checkmark-icon {
        fill: $navigio-blue;
      }
    }
  }

  &.delayed {
    border-color: $red;

    .timeline-progress {

      .circled-icon {
        border-color: $red;
      }

      .checkmark-icon {
        fill: $red;
      }
    }

    .milestone-events:before {
      background-color: $red;
    }

    .milestone-indicator {

      .doc-icon,
      .profile-icon,
      .checkmark-icon {
        fill: $red;
      }
    }
  }

  // Status

  &.status-good {
    border-color: $green!important;

    .timeline-progress {

      .circled-icon {
        border-color: $green!important;
      }

      .checkmark-icon {
        fill: $green!important;
      }
    }

    .milestone-events:before {
      background-color: $green!important;
    }

    .milestone-indicator {

      .doc-icon,
      .profile-icon,
      .checkmark-icon {
        fill: $green!important;
      }
    }
  }

  &.status-warning {
    border-color: $warning!important;

    .timeline-progress {

      .circled-icon {
        border-color: $warning!important;
      }

      .checkmark-icon {
        fill: $warning!important;
      }
    }

    .milestone-events:before {
      background-color: $warning!important;
    }

    .milestone-indicator {

      .doc-icon,
      .profile-icon,
      .checkmark-icon {
        fill: $warning!important;
      }
    }
  }

  &.status-danger {
    border-color: $red!important;

    .timeline-progress {

      .circled-icon {
        border-color: $red!important;
      }

      .checkmark-icon {
        fill: $red!important;
      }
    }

    .milestone-events:before {
      background-color: $red!important;
    }

    .milestone-indicator {

      .doc-icon,
      .profile-icon,
      .checkmark-icon {
        fill: $red!important;
      }
    }
  }
}




// Candidates Section

.project-candidates-wrapper {
  display: flex;
  flex-direction: column;
  padding: 25px 0 0;
  background: $dark-grey;
  color: #fff;
  overflow: hidden;

  @media screen and (min-width: 1024px) {
    padding: 35px 0 0;
  }
}

.project-candidates {
  @include clear-list;
  display: flex;
  overflow: auto;
  padding-bottom: 20px;
  -webkit-overflow-scrolling: touch;
  // -ms-overflow-style: none;
  // &::-webkit-scrollbar {
  //   display: none;
  // }

  @media screen and (min-width: 1024px) {
    margin: 2rem auto 0;
    padding-bottom: 30px;
    max-width: 100%;
  }

  .notifications-indicator {
    position: absolute;
    top: -9px;
    left: 50%;
    margin-left: 9px;
  }
}

.project-candidate {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;

  & .thumbnail-wrapper {
    margin: 0 auto 10px;
  }
}

.candidate-open {
  padding: 0 10px;
  transition: .2s ease-in-out;
  opacity: 1;
  text-align: center;
  -webkit-appearance: none !important;
  .project-candidates & {
    width: 16rem;

    @media screen and (min-width: 1024px) {
      width: 18rem;
    }
  }

  .rejected & {
    opacity: 0.3;

    &:hover,
    &:focus {
      opacity: 1;
    }
  }
}

.candidate-add {
  padding: 0 25px;
}


.milestone-event-list {
  margin-top: 12px;
}

.open-parent {
  position: relative;

  .arrow-icon {
    position: absolute;
    top: 50%;
    right: 0;
    width: 20px;
    height: 20px;
    margin-top: -10px;
    fill: $text-grey;
  }

  .arrow-down-icon {
    display: block;
  }

  .arrow-up-icon {
    display: none;
  }

  &.open {
    .arrow-down-icon {
      display: none;
    }

    .arrow-up-icon {
      display: block;
    }
  }

  @media screen and (min-width: 1024px) {
    .arrow-icon {
      visibility: hidden;
    }

    &:hover {
      .arrow-icon {
        visibility: visible;
      }
    }
  }
}

.toggle-all {
  @include fontSize(14px);
  position: absolute;
  top: 20px;
  right: 15px;
  color: $text-grey;

  @media screen and (min-width: 1024px) {
    top: 56px;
    right: 68px;
  }

  &.open {
    color: $black;
    font-weight: $medium;
  }
}


.pie {
  width: 20px; height: 20px;
  transform: rotate(-90deg);
  background: $grey-base;
  border-radius: 50%;

  circle {
    fill: $grey-base;
    stroke: $navigio-blue;
    stroke-width: 32;
    transition: stroke-dasharray 1s ease;
  }

  .active & {
    circle {
      stroke: $navigio-blue;
    }
  }

  .upcoming & {
    circle {
      stroke: $border-color;
    }
  }

  .delayed & {
    circle {
      stroke: $red;
    }
  }

  .status-danger & {
    circle {
      stroke: $red !important;
    }
  }

  .status-good & {
    circle {
      stroke: $green !important;
    }
  }

  .status-warning & {
    circle {
      stroke: $warning !important;
    }
  }
}
