.clear-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.letters-list,
.letters-list-list,
.projects-list,
.listed {
  @include clear-list();
}

.letters-list-list-item {
  display: flex;
  align-items: center;
  min-height: 65px;
  padding: 1rem;
  background: #fff;
  border-bottom: 1px solid $border-color;

  @media screen and (min-width: 1024px) {
    min-height: 70px;
  }
}

.letter-list-col {
  flex: 1 1 100%;
}

.letters-list-letter {
  @include fontSize(12px);
  display: block;
  padding: 1rem;
  border-bottom: 1px solid $border-color;
  background: $grey-base;
  color: $text-grey;
  font-weight: $medium;
  text-transform: uppercase;

  .letters-list-item:first-child & {
    border-top: 1px solid $border-color;
  }

  @media screen and (min-width: 1024px) {
    @include fontSize(28px);
    border: none;
    background: none;
    color: $grey-blue;

    .letters-list-item:first-child & {
      border: none;
    }
  }
}


.listed-item-cta {
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 60px;
  padding: 10px 1rem;
  background: #fff;
  border-bottom: 1px solid $border-color;
}
