.log-head {
  margin-bottom: 15px;
  padding: 15px 20px;
  border-bottom: 1px solid $border-color;

  @media screen and (min-width: 1200px) {
    padding: 20px;
  }
}

.log-header {
  @include fontSize(16px);
  font-weight: $regular;
  margin: 0;

  @media screen and (min-width: 1200px) {
    @include fontSize(18px);
  }
}
