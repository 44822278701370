// http://aslanbakan.com/en/blog/less-space-responsive-css-margin-and-padding-helper-classes/

// change to false if its not imported into bootstrap
$use-bootstrap: false;

// margin and padding values array
$space-values : (
  0,
  5,
  10,
  12,
  15,
  20,
  25,
  30,
  35,
  40,
  50,
  60,
  70,
  75,
  80,
  90,
  100
) !default;

// margin and padding shorthands
$space-prefixes : (
  p  : padding,
  pt : padding-top,
  pr : padding-right,
  pb : padding-bottom,
  pl : padding-left,
  m  : margin,
  mt : margin-top,
  mr : margin-right,
  mb : margin-bottom,
  ml : margin-left,
) !default;

// change these values if its not imported into bootstrap
$grid-breakpoints-custom: (
  xxs: 0,
  xs: 320px,
  sm: 480px,
  md: 768px,
  lg: 1024px,
  xl: 1140px,
  xxl: 1280px
) !default;

$breakpoints : $grid-breakpoints-custom;
@if $use-bootstrap {
  $breakpoints : $grid-breakpoints;
}

@function calculateRemSize($size) {
  $remSize: $size / 10;
  @return #{$remSize}rem;
}

// main function definition
@mixin make-space($values, $prefixes, $breakpoints) {
  @each $breakpoint-name, $breakpoint-value in $breakpoints {
    // if xs value = 0, set it global without media queries
    @if($breakpoint-value == 0) {

      @each $value in $values {

        .#{$breakpoint-name}-px-#{$value} {
          padding-left: calculateRemSize($value) !important;
          padding-right: calculateRemSize($value) !important;
        }

        .#{$breakpoint-name}-py-#{$value} {
          padding-top: calculateRemSize($value) !important;
          padding-bottom: calculateRemSize($value) !important;
        }

        .#{$breakpoint-name}-mx-#{$value} {
          margin-left: calculateRemSize($value) !important;
          margin-right: calculateRemSize($value) !important;
        }

        .#{$breakpoint-name}-my-#{$value} {
          margin-top: calculateRemSize($value) !important;
          margin-bottom: calculateRemSize($value) !important;
        }

        @each $attr-short, $attr-long in $prefixes {

          .#{$breakpoint-name}-#{$attr-short}-#{$value} {
            #{$attr-long}: calculateRemSize($value) !important;
          }

        }
      }
    }
    // breakpoint values that not equal to 0
    @else {
      @media screen and (min-width: $breakpoint-value) {
        @each $value in $values {

          .#{$breakpoint-name}-px-#{$value} {
            padding-left: calculateRemSize($value) !important;
            padding-right: calculateRemSize($value) !important;
          }

          .#{$breakpoint-name}-py-#{$value} {
            padding-top: calculateRemSize($value) !important;
            padding-bottom: calculateRemSize($value) !important;
          }

          .#{$breakpoint-name}-mx-#{$value} {
            margin-left: calculateRemSize($value) !important;
            margin-right: calculateRemSize($value) !important;
          }

          .#{$breakpoint-name}-my-#{$value} {
            margin-top: calculateRemSize($value) !important;
            margin-bottom: calculateRemSize($value) !important;
          }

          @each $attr-short, $attr-long in $prefixes {

            .#{$breakpoint-name}-#{$attr-short}-#{$value} {
              #{$attr-long}: calculateRemSize($value) !important;
            }

          }
        }
      }
    }
  }
}

@include make-space($space-values, $space-prefixes, $breakpoints);
