.notifications-list {
  @include clear-list();
}

.log-list {
  @include clear-list();
  margin: 0 25px 0 20px;
}

.log-list-item {
  display: flex;
  align-items: center;
}

.log-list-col {
  margin-left: 12px;

  &:first-child {
    margin-left: 0;
  }
}

.log {
  @include fontSize(14px);

  @media screen and (min-width: 1200px) {
    @include fontSize(16px);
  }
}

.log-person,
.log-action-link {
  font-weight: $medium;
  text-decoration: none;
}

.log-person {
  color: $black;
}

.log-action-link {
  color: $navigio-blue;
}

.log-date {
  @include fontSize(14px);
  white-space: nowrap;
  color: $text-grey;
}

.notifications-list-sm {

  .log-list-item {
    justify-content: space-between;
    padding: 0 0 14px;
  }

  .log {
    @include fontSize(14px);
    line-height: 1.5;
    margin: 0;
  }

  .log-date {
    @include fontSize(12px);
    margin-bottom: 3px;
  }

  .log-col-middle {
    flex: 1 1 auto;
    margin: 0 5px;
  }

  .log-col-right {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
}

.pill {
  @extend .btn-style;
  @extend .btn-neutral;
  @extend .btn-rounded;
  @include fontSize(12px);
  padding: 3px 8px;
}

.signed-pill {
  background: $navigio-blue;
  color: #FFF;
  font-weight: $bold;
}

.omega-pill {
  background: $green;
  color: #FFF;
  font-weight: $bold;
}
