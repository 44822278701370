.content-search {
  padding: 15px;
  background: #fff;

  @media screen and (min-width: 1024px) {
    display: flex;
    align-items: center;
    flex: 0 0 auto;
    height: 80px;
    padding: 0;
    background: $darker-grey;
    border-bottom: 1px solid $border-color;
  }
}

.content-search-input {
  width: 100%;
  padding: 0.625rem;
  background: $grey-base;
  border: none;
  border-radius: 3px;
  text-align: center;
  -webkit-appearance: none;

  @media screen and (min-width: 1024px) {
    @include fontSize(18px);
    padding: 1.25rem 50px 1rem;
    background: transparent;
    border-radius: 0;
    text-align: left;

    &::-webkit-input-placeholder {
      font-style: italic;
    }

    &:-moz-placeholder {
      font-style: italic;
    }

    &::-moz-placeholder {
      font-style: italic;
    }

    &:-ms-input-placeholder {
      font-style: italic;
    }
  }
}
