$input-width: 100%;
$input-height: 50px;

.hidden-input {
  position: absolute;
  left: -9999px;
}

.input-wrapper {
  position: relative;
}

.input-wrapper-form {
  margin-bottom: 15px;
}

.input-wrapper-clip {
  position: relative;
  overflow: hidden;
}

.label {
  display: block;
  position: absolute;
  width: 100%;
  line-height: 1;
}

.input {
  @include placeholder-color($black);
  width: $input-width;
  height: $input-height;
  padding: 10px 0 0;
  border-width: 0 0 1px;
  border-style: solid;
  border-color: $border-color;
  border-radius: 0;
  background: transparent;
  -webkit-appearance: none;

  &:focus {
    border-color: $navigio-blue;

    & + .input-ball-index {
      background: #fff;
      color: $navigio-blue;
    }
  }

  .error & {
    border-color: $red;
  }

  &:disabled {
    @include placeholder-color($text-grey);
  }
}

.label-small {
  @include fontSize(12px);
  color: $label-grey;
}

.label-fancy {
  bottom: 100%;
  transform: translateY(0);
  opacity: 0;
  transition: all 200ms ease-in-out;
  line-height: normal;

  &-date {
    transition-delay: 90ms;
  }
}

.input-fancy {
  padding: 0;
  transition: padding 200ms ease-in-out;

  &:focus,
  &.value {
    padding-top: 10px;

    + .label-fancy {
      opacity: 1;
      transform: translateY(14px);
      transition: all 200ms ease-in-out;
    }
  }
}

.label-inside {
  padding: 8px 10px 0;
}

.input-style {
  @extend .input;
  padding: 17px 0 0;
}

.input-rounded {
  @include placeholder-color($black);
  width: $input-width;
  height: $input-height;
  padding: 14px 10px 0;
  border: 1px solid $menu-grey;
  border-radius: $border-radius;
  background: #fff;
  -webkit-appearance: none;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.06);
}

.input-square {
  @include placeholder-color($black);
  width: $input-width;
  height: $input-height;
  padding: 14px 10px 0;
  border: 1px solid transparent;
  border-radius: none;
  background: #fff;
  -webkit-appearance: none;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.06);

  &:focus {
    border-color: $navigio-blue;
  }

  .error & {
    border-color: $red;
  }
}

.input-naked {
  width: 100%;
  padding: 0;
  border: none;
}

.input-comment {
  @include placeholder-color($navigio-blue);
}

.input-new-milestone {

  &::-webkit-input-placeholder {
    font-style: italic;
    color: $text-grey;
    opacity: 1;
  }

  &:-moz-placeholder {
    font-style: italic;
    color: $text-grey;
    opacity: 1;
  }

  &::-moz-placeholder {
    font-style: italic;
    color: $text-grey;
    opacity: 1;
  }

  &:-ms-input-placeholder {
    font-style: italic;
    color: $text-grey;
    opacity: 1;
  }
}

// hides selects from users with vision
.select,
.input-file {
  position: absolute;
  left: -9999px;
}

.label-file {
  color: $navigio-blue;
  text-decoration: underline;
  cursor: pointer;
}

.label-inside-search {
  position: absolute;
  top: 50%;
  left: 12px;
  transform: translateY(-50%);
  line-height: 1;
}

.search-select-wrapper {
  position: relative;

  .label-inside-search {
    left: 5px;
  }
}

.search-select {
  @include fontSize(14px);
  @include placeholder-color($label-grey);
  padding: 5px 5px 5px 24px;
  font-weight: $medium;
}

// Radio buttons
.special-radio-label {
  display: inline-block;
  padding: 9px 12px;
  border-radius: $border-radius;
  cursor: pointer;
}

.special-radio {
  &:checked + .special-radio-label {
    background: $neutral-grey;
    color: $navigio-blue;
    font-weight: $medium;
    cursor: default;
  }

  &:disabled + .special-radio-label {
    color: $text-grey;
    cursor: default;
  }
}

.datepicker {
  position: relative;
  z-index: 2;
  cursor: pointer;
}

.datepicker-new-milestone {
  &::-webkit-input-placeholder {
    text-decoration: underline;
    color: $text-grey;
    opacity: 1;
  }

  &:-moz-placeholder {
    text-decoration: underline;
    color: $text-grey;
    opacity: 1;
  }

  &::-moz-placeholder {
    text-decoration: underline;
    color: $text-grey;
    opacity: 1;
  }

  &:-ms-input-placeholder {
    text-decoration: underline;
    color: $text-grey;
    opacity: 1;
  }
}

.checkbox-label {
  margin-left: 40px;
  cursor: pointer;
}

.checkbox-hidden {
  position: absolute;
  z-index: -1;
  opacity: 0;

  &:checked {
    & + .checkbox-marker:after {
      opacity: 1;
    }
  }
}

.checkbox-marker {
  display: block;
  position: absolute;
  top: 50%;
  width: 30px;
  height: 30px;
  margin-top: -15px;
  border: 2px solid #fff;
  cursor: pointer;

  &:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 20px;
    height: 20px;
    margin: -10px 0 0 -10px;
    background: #fff;
    opacity: 0;
    transition: opacity 150ms ease-in;
    cursor: pointer;
  }

  &-round {
    border-radius: 50%;

    &:after {
      border-radius: 50%;
    }
  }
}

.label-light {
  color: #fff;
}

.navigio-radio {
  display: flex;
  align-items: center;
  padding-right: 10px;
  cursor: pointer;

  .tickbox {
    position: relative;
    margin-right: 5px;
    display: inline-block;
    width: 20px;
    height: 20px;
    border-radius: 100%;
    border: 2px $text-grey solid;

    &:before {
      content: "";
      display: block;
      position: absolute;
      width: 8px;
      height: 8px;
      border-radius: 100%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background: #ddd;
    }
  }

  .text {
    @include fontSize(16px);
  }

  input[type="radio"]:checked ~ .tickbox {
    border: 2px $navigio-blue solid;
  }

  input[type="radio"]:checked ~ .tickbox:before {
    background: $navigio-blue;
  }

  input[type="radio"] {
    position: absolute;
    visibility: hidden;
  }
}

.input-ball-index {
  background: rgba(255,255,255,0.3);
  height: 35px;
  width: 35px;
  margin-right: 15px;
  border-radius: 100%;
  line-height: 35px;
  order: -1;
  @include fontSize(16px);
  transition: background 0.15s, color 0.15s;
  text-align: center;
}
