.todos-list,
.todo-list {
  @include clear-list();
}

@media screen and (min-width: 1024px) {
  .todos-list-item {
    &.open {
      .todo-list {
        border-left: 10px solid $border-color;
      }
    }
  }
}

.todos-list-head {
  display: flex;
  align-items: center;
  padding: 1rem 15px;
  border-width: 0 0 1px;
  border-style: solid;
  border-color: $border-color;
  background: $grey-base;

  @media screen and (min-width: 1024px) {
    height: 60px;
    padding: 15px;
    background: #fff;
  }

  &-btn {
    width: 100%;
    text-align: left;
    padding: 0;
    border: none;
    background: none;
    color: $text-grey;

    @media screen and (min-width: 1024px) {
      color: $black;
      cursor: pointer;
    }
  }
}

.todos-list-btn-wrapper {
  flex: 1 1 auto;
}

.todos-list-header {
  @include fontSize(12px);
  font-weight: $medium;
  text-transform: uppercase;
  margin: 0;

  @media screen and (min-width: 1024px) {
    @include fontSize(18px);
    font-weight: $regular;
    text-transform: none;
  }
}



.todo-list-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 70px;
  padding: 10px 15px;

  &:last-child {
    border-bottom: 1px solid $border-color;
  }

  @media screen and (min-width: 1024px) {
    border-bottom: 1px solid $border-color;
  }

  .ellipsis-btn {
    visibility: hidden;
  }

  &:hover {
    @media screen and (min-width: 1024px) {
      position: relative;
      // z-index: 1;
      box-shadow: 0 1px 6px rgba(0,0,0,.2);

      .ellipsis-btn {
        visibility: visible;
      }

      .flag-btn {

        .fa-flag {
          visibility: visible;
        }

        &:disabled {
          .fa-flag {
            visibility: hidden;
          }
        }
      }
    }
  }
}

.todo-btn-wrapper {
  flex: 0 0 auto;
  // margin-left: 15px;

  // &:last-of-type {
  //   margin-left: 30px;
  //   margin-right: 15px;
  // }
}

.todo-task-wrapper {
  flex: 1 1 auto;

  @media screen and (min-width: 1024px) {
    flex: 1 1 100%;
  }
}

.todo-thumbnails-wrapper {
  @include fontSize(12px);
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  flex-wrap: wrap;
  width: 72px;
  color: $text-grey;

  @media screen and (min-width: 1024px) {
    justify-content: flex-end;
    margin-left: 20px;
  }
}

.username-shared {

  @media screen and (max-width: 1023px) {
    margin-left: 6px;
    white-space: nowrap;
  }
}

.todo-time-wrapper {
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  width: 95px;
  margin: 0 10px 0 22px;

  &-head {
    margin-right: 54px;
  }
}

.todo-task {
  @include fontSize(14px);
  margin: 0;
  line-height: 1.4;
  overflow: hidden;
  text-overflow: ellipsis;

  @media screen and (min-width: 1200px) {
    @include fontSize(16px);
  }
}

.todo-due-date {
  @include fontSize(14px);
  color: $text-grey;
}

.todo-due-date {
  white-space: nowrap;

  .todo-list-item.delayed & {
    color: lighten($red, 5%);
  }
}



.todos-list-item.upcoming,
.todo-list-item.upcoming {

  .circled-icon {
    border-color: $navigio-blue;
  }

  .checkmark-icon {
    fill: #fff;
  }

  @media screen and (min-width: 1024px) {
    .checkmark-btn {
      &:hover {
        .checkmark-icon {
          fill: $navigio-blue;
        }
      }
    }
  }
}

.todos-list-item.delayed,
.todo-list-item.delayed {

  .circled-icon {
    border-color: $red;
  }

  .checkmark-icon {
    fill: #fff;
  }

  @media screen and (min-width: 1024px) {
    .checkmark-btn {
      &:hover {
        .checkmark-icon {
          fill: $red;
        }
      }
    }
  }
}

.todos-list-item.done,
.todos-list-item.done.closed,
.todo-list-item.done {

  .circled-icon {
    border-color: $green;
  }

  .checkmark-icon {
    fill: $green;
  }

  @media screen and (min-width: 1024px) {
    .checkmark-btn {
      &:hover {
        .checkmark-icon {
          fill: $green;
        }
      }
    }
  }
}

.todos-list-item.upcoming.closed,
.todos-list-item.delayed.closed {
  .circled-icon {
    border-color: $border-color;
    color: $border-color;
  }
}




.flag-btn {

  .fa-flag {
    @include fontSize(18px);
    color: $border-color;
  }

  @media screen and (min-width: 1024px) {
    .fa-flag {
      visibility: hidden;
    }
  }

  &.flagged {

    .fa-flag {
      color: $red;
      visibility: visible;
    }
  }

  &:disabled {
    .fa-flag {
      visibility: hidden;
    }
  }
}

.todos-new-milestone {
  display: flex;
  align-items: center;
  height: 0;
  padding: 0 15px;
  border-width: 0 0 1px;
  border-style: solid;
  border-color: transparent;
  overflow: hidden;
  transition: all 150ms;

  &.active {
    height: 60px;
    border-color: $border-color;
  }

  .datepicker {
    border: none;
  }
}

.new-milestone-name {
  flex: 1 1 100%;
  margin-left: 28px;
}

.new-milestone-save {
  width: 88px;
}

.new-milestone-form {
  display: flex;
  flex-grow: 1;
}

.btn-new-wrapper {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 50px;
  height: 50px;
  box-shadow: 0 15px 16px rgba(0,0,0,.2);
  border-radius: 30px;
  overflow: hidden;
  z-index: 1;

  @media screen and (min-width: 1024px) {
    bottom: 60px;
    right: 40px;
    width: 60px;
    height: 60px;
  }
}

.btn-new-extended {
  display: flex;
  align-items: center;
  padding: 0;
  background: $navigio-blue;
  transition: all 150ms;

  &.open {
    padding-left: 15px;
    width: 230px;

    @media screen and (min-width: 1024px) {
      padding-left: 20px;
      width: 250px;
    }
  }
}

.btn-add {
  position: absolute;
  right: 0;
  box-shadow: 0 15px 16px rgba(0,0,0,.2);

  &.active {
    background: $navigio-blue;

    // .cross-icon {
    //   transform: rotate(90deg);
    // }
  }
}

.close-icon {
  fill: #fff;
  width: 13px;
  height: 13px;
}

.btn-extended {
  color: #fff;
  padding: 0 10px;
}
