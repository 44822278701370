$headerPaddingSm: 15px;

.profile-header {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  padding: $headerPaddingSm;
  background: $dark-grey;

  @media screen and (min-width: 1024px) {
    padding: 3rem 5rem;
  }
}

.profile-header-col {
  display: flex;
  flex-direction: column;
  margin: 1rem 0 0;
}

.profile-content-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media screen and (min-width: 1024px) {
    flex-direction: row;
  }
}

.cta-profile {
  margin-top: 1.5rem;

  @media screen and (min-width: 1024px) {
    position: absolute;
    top: 3rem;
    right: 3rem;
    margin-top: 0;
  }
}

.profile-content-box {
  margin: 15px;
  padding: 20px;
  background: #fff;
  box-shadow: 0 1px 0 #e8e8e8;

  &:first-child {
    margin-bottom: 0;
  }

  @media screen and (min-width: 1024px) {
    width: 50%;
    margin: 40px 0 40px 30px;
    padding: 40px;

    &:first-child {
      margin: 40px 0;
    }
  }
}

.profile-stats {
  display: flex;
  flex-wrap: wrap;
  padding: 20px;

  @media screen and (min-width: 1024px) {
    padding: 80px;
  }
}

.profile-stat {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 50%;
  padding: 20px;

  @media screen and (min-width: 1024px) {
    justify-content: center;
    padding: 0;
  }
}

.counting {
  @include fontSize(14px);
  font-weight: $medium;
  text-transform: uppercase;
  color: $grey-blue;
  margin-top: 5px;
  text-align: center;
}

.counter-lg {
  @include fontSize(40px);
  display: inline-block;
  min-width: 60px;
  height: 60px;
  padding: 0 10px;
  line-height: 60px;
  background: $grey-base;
  color: $navigio-blue;
  border-radius: 30px;
  text-align: center;
}

.profile-img {
  position: relative;
  cursor: pointer;

  .thumbnail-wrapper {
    transition: .2s ease-in-out;
  }

  .fa {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    color: #fff;
    opacity: 0;
    transition: .2s ease-in-out;
  }

  &:hover {

    .thumbnail-wrapper {
      opacity: .3;
    }

    .fa {
      opacity: 1;
      transform: translateY(-50%) translateX(-50%) scale(1.5);
    }
  }
}
