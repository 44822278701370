.clear-btn {
  padding: 0;
  border: none;
  background: none;
  cursor: pointer;
  -webkit-appearance: none;

  &:disabled {
    cursor: auto;
  }
}

.btn-no-style {
  @extend .clear-btn;

  &:active {
    color: currentColor;
  }

  &.disabled,
  &:disabled {
    color: #c3c3c3;
    cursor: default;
  }
}

.btn {
  @extend .clear-btn;

  &:active {
    color: currentColor;
  }

  &.disabled,
  &:disabled {
    background: $neutral-grey;
    color: #c3c3c3;
    cursor: default;

    &:focus {
      outline: none;
    }

    .arrow-right-icon{
      fill: #c3c3c3;
    }
  }
}

.btn-label {
  position: absolute;
  left: -9999px;
}

.btn-round {
  border-radius: 50%;
}

.btn-rounded {
  border-radius: $border-radius;
}

.btn-full-width {
  width: 100%;
}

.btn-xs {
  @include fontSize(12px);
  height: 25px;
  padding: 0 8px;
  line-height: 25px;
}

.btn-sm {
  @include fontSize(14px);
  height: 33px;
  padding: 0 14px;
  line-height: 33px;
}

.btn-md {
  padding: 0 20px;
  height: 50px;
  font-weight: $medium;
  line-height: 50px;
}

.btn-more {
  @extend .clear-btn;
  width: 25px;
  height: 25px;
}

.btn-style {
  display: inline-flex;
  align-items: center;
  text-decoration: none;
  // line-height: 1;
}

.btn-link-style {
  @extend .clear-btn;
  color: $navigio-blue;
  cursor: pointer;
}

.notifications-btn-active {
  .menu-icon-sm {
    background: $orange;
    color: darken($dark-blue, 30%);
    border-color: $orange;
  }
}

.menu-icon {
  display: block;
  position: relative;
  width: 20px;
  height: 2px;
  margin: 0 auto;
  background: #fff;

  &:before,
  &:after {
    content: '';
    position: absolute;
    left: 0;
    width: 100%;
    height: 2px;
    background: #fff;
  }

  &:before {
    top: -7px;
  }

  &:after {
    bottom: -7px;
  }
}

.btn-success {
  background: $green;
  color: #fff;

  &:active {
    color: #fff;
  }
}

.btn-danger {
  background: $red;
  color: #fff;
}

.btn-primary {
  background: $navigio-blue;
  color: #fff;
}

.btn-neutral {
  background: $neutral-grey;
  color: $black;
}


.btn-neutral-glam {
  background: $neutral-grey;
  color: $navigio-blue;
}

.btn-transparent {
  background: rgba(256,256,256,0.1);
  color: #fff;

  &.disabled {
    color: rgba(256,256,256,0.3);
  }
}

.icon-btn {
  @extend .clear-btn;
  width: 44px;
  height: 44px;

  &-content {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &-xs {
    width: 20px;
    height: 20px;
  }

  &-sm {
    width: 34px;
    height: 34px;
  }

  &-md {
    width: 40px;
    height: 40px;

    @media screen and (min-width: 1024px) {
      width: 56px;
      height: 56px;
    }
  }

  &-lg {
    width: 50px;
    height: 50px;

    @media screen and (min-width: 1024px) {
      width: 60px;
      height: 60px;
    }
  }

  .ellipsis-icon {
    width: 18px;
    height: 4px;
    fill: $border-color;
  }
}

.btn-close {
  @extend .btn-round;
  position: absolute;
  top: 0;
  right: 0;
  line-height: 1;

  .close-icon {
    fill: $black;
  }

  @media screen and (min-width: 1024px) {
    top: 30px;
    right: 40px;
    background: rgba(256,256,256,1);
    transition: background .2s ease-in-out;

    &:focus,
    &:hover {
      background: rgba(256,256,256,0.8);
    }
  }
}

.btn-remove {
  @extend .btn-close;
  top: -10px;
  right: -10px;
  z-index: 100;

  @media screen and (min-width: 1024px) {
    top: -10px;
    right: -10px;
  }

  &-mini {
    right: 0;
    top: 50%;
    height: 14px;
    width: 14px;
    margin-top: -7px;
    border: none;

    .close-icon {
      width: 6px;
      height: 6px;
    }
  }
}

.new-project-choice {
  @extend .clear-btn;
  position: relative;
  width: 200px;
  height: 80px;
  margin: 0 10px;
  overflow: hidden;
  box-shadow: 0 1px 4px rgba(0,0,0,.4);

  .primary-text {
    color: $navigio-blue;
    font-weight: $medium;
    transform: translateY(-50%);
  }

  .hover-text {
    @include fontSize(12px);
    display: none;
    color: $grey-blue;

    @media screen and (min-width: 1024px) {
      display: block;
      transform: translateY(45px);
    }
  }

  .primary-text,
  .hover-text {
    position: absolute;
    top: 50%;
    right: 0;
    left: 0;
    text-align: center;
    transition: transform 0.25s;
  }

  @media screen and (min-width: 1024px) {
    &:hover {

      .primary-text {
        transform: translateY(-20px);
      }

      .hover-text {
        transform: translateY(4px);
      }
    }
  }
}

.btn-content {
  display: flex;
  align-items: center;
}
