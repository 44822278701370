// Table list of files
$iconWidth: 45px;
$iconWidth-lg: 54px;

$titleWidth: 140px;
$titleWidth-lg: 150px;

$categoryWidth: 80px;

$ownerWidth: 140px;
$ownerWidth-lg: 150px;

$sharedWidth: 128px;

$dateWidth: 72px;
$dateWidth-lg: 82px;

$moreWidth: 44px;

//Table list of projects

$process-lg: 220px;

.tbl-head-icons {
  position: absolute;
  top: 0;
  right: 0;
}

.tbl-row {
  @include fontSize(14px);
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  width: 100%;
  height: 70px;
  padding: 0 15px;
  border-bottom: 1px solid $border-color;

  .dropdown-more-wrapper {
    visibility: hidden;
  }

  &:hover {
    position: relative;
    box-shadow: 0 1px 6px rgba(0,0,0,.2);

    .dropdown-more-wrapper {
      visibility: visible;
    }
  }

  @media screen and (min-width: 1200px) {
    @include fontSize(16px);
  }

  .projects-table & { //Table list of projects
    background: #fff;
  }

  .surveys-table & {
    height: auto;
    min-height: 70px;
    padding: 1rem 15px;
  }
}

.tbl-col {
  position: relative;
  flex-grow: 0;
  flex-shrink: 0;

  @media screen and (min-width: 1024px) {
    margin-right: 20px;
  }

  @media screen and (min-width: 1200px) {
    margin-right: 30px;
  }

  .tbl-head & {
    cursor: pointer;
  }
}

.tbl-head {
  @include fontSize(13px);
  display: none;
  height: 60px;
  font-weight: $bold;
  text-transform: uppercase;

  @media screen and (min-width: 1024px) {
    display: flex;
  }

  &:hover {
    box-shadow: none;
  }
}

.fluid-content {
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  &-lg {
    @media screen and (min-width: 1024px) {
      display: block;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}


.right-aligned {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;

  .todo-list & {
    overflow: hidden;

    @media screen and (min-width: 1024px) {
      overflow: visible; // To show tooltip
    }
  }

  @media screen and (min-width: 1024px) {
    flex-direction: row;
    align-items: center;
  }
}

.tbl-col-1 {

  .projects-table & { //Table list of projects
    display: none;

    @media screen and (min-width: 1024px) {
      display: block;
      width: 42px;
    }
  }

  .files-table &,
  .surveys-table & { //Table list of files
    width: $iconWidth;
    margin: 0;

    @media screen and (min-width: 1200px) {
      width: $iconWidth-lg;
    }
  }

  .users-table & {
    width: 45px;
    margin: 0;
  }
}

.tbl-col-2 {

  .projects-table & { //Table list of projects

    @media screen and (min-width: 1024px) {
      flex-grow: 1;
      width: $titleWidth;
    }

    @media screen and (min-width: 1200px) {
      width: $titleWidth-lg;
    }
  }

  .files-table & { //Table list of files
    flex-grow: 2;

    @media screen and (min-width: 1024px) {
      width: $titleWidth;
    }

    @media screen and (min-width: 1200px) {
      width: $titleWidth-lg;
    }
  }

  .surveys-table & {

    @media screen and (min-width: 1024px) {
      width: 190px;
      flex-grow: 2;
    }
  }

  .users-table & {

    @media screen and (min-width: 1024px) {
      flex-grow: 2;
      width: 140px;
    }
  }
}

.tbl-col-3 {

  .projects-table & { //Table list of projects
    order: -1;

    @media screen and (min-width: 1024px) {
      flex-grow: 1;
      order: initial;
      width: $titleWidth;
    }

    @media screen and (min-width: 1200px) {
      width: $titleWidth-lg;
    }
  }

  .files-table & { //Table list of files
    order: -1;

    @media screen and (min-width: 1024px) {
      width: $categoryWidth;
      order: initial;
    }
  }

  .surveys-table & {

    @media screen and (min-width: 1024px) {
      width: 130px;
      flex-grow: 1;
    }
  }

  .users-table & {

    @media screen and (min-width: 1024px) {
      flex-grow: 1;
      width: 140px;
    }
  }
}

.tbl-col-4 {

  .projects-table & { //Table list of projects
    display: none;

    @media screen and (min-width: 1024px) {
      display: block;
      width: $process-lg;
    }
  }

  .files-table & { //Table list of files
    display: none;
    flex-grow: 1;
    width: $ownerWidth;

    @media screen and (min-width: 1024px) {
      display: block;
    }

    @media screen and (min-width: 1200px) {
      width: $ownerWidth-lg;
    }
  }

  .surveys-table & {

    @media screen and (min-width: 1024px) {
      width: 130px;
      // flex-grow: 1;
    }
  }

  .users-table & {
    display: none;

    @media screen and (min-width: 1024px) {
      display: block;
      width: 115px;
    }

    @media screen and (min-width: 1200px) {
      width: 135px;
    }
  }
}

.tbl-col-5 {

  .projects-table & { //Table list of projects
    display: none;

    @media screen and (min-width: 1024px) {
      display: block;
    }

    @media screen and (min-width: 1200px) {
      width: $ownerWidth-lg;
    }
  }

  .files-table & { //Table list of files
    display: none;
    width: $sharedWidth;

    @media screen and (min-width: 1024px) {
      display: flex;
      // justify-content: flex-end;

      .thumbnail-wrapper {
        margin-left: 6px;

        &:first-child {
          margin-left: 0;
        }
      }
    }
  }

  .surveys-table & {
    width: 65px;
    text-align: right;

    @media screen and (min-width: 1024px) {
      width: 80px;
    }
  }

  .users-table & {
    display: none;
    width: $dateWidth;

    @media screen and (min-width: 1024px) {
      display: block;
    }

    @media screen and (min-width: 1200px) {
      width: $dateWidth-lg;
    }
  }
}

.tbl-col-6 {
  &.no-edit {
    @media screen and (min-width: 1024px) {
      margin-right: 60px;
    }

    @media screen and (min-width: 1200px) {
      margin-right: 70px;
    }
  }

  .files-table &,
  .surveys-table & { //Table list of files
    display: none;
    width: $dateWidth;

    @media screen and (min-width: 1024px) {
      display: block;
    }

    @media screen and (min-width: 1200px) {
      width: $dateWidth-lg;
    }
  }

  .users-table & {
    position: absolute;
    right: 1.5rem;
    width: 10px;
    height: 10px;
    border-radius: 10px;
    text-indent: -100%;
    white-space: nowrap;
    overflow: hidden;

    @media screen and (min-width: 1024px) {
      position: relative;
      right: auto;
      width: 85px;
      height: auto;
      border-radius: none;
      overflow: visible;
      white-space: normal;
      text-indent: 0;
      background: none;
    }
  }
}

.tbl-notifications {
  width: 40px;
}

.tbl-more {
  display: none;
  width: $moreWidth;
  margin-right: 0;

  @media screen and (min-width: 1024px) {
    display: block;
  }
}

.tbl-tagged {
  @include fontSize(12px);
  font-weight: $medium;
  line-height: 1;
  color: $grey-blue;
  text-transform: uppercase;

  @media screen and (min-width: 1024px) {
    @include fontSize(14px);
    font-weight: $regular;
    line-height: inherit;
    color: $black;
    text-transform: none;
  }

  @media screen and (min-width: 1200px) {
    @include fontSize(16px);
  }
}

.projects-table {
  .progressbar {
    margin: 0 0 8px;
  }
}

.status-finished {
  background: $green;

  @media screen and (min-width: 1024px) {
    color: $green;
  }
}

.status-active {
  background: $orange;

  @media screen and (min-width: 1024px) {
    color: $orange;
  }
}

.status-archived {
  background: $text-grey;

  @media screen and (min-width: 1024px) {
    color: $text-grey;
  }
}
