.projects-grid,
.projects-table {
  @media screen and (min-width: 1024px) {
    padding-top: 40px;
    padding-bottom: 25px;
  }
}

.project {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
}

.project-header {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  height: 70px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-color: #bdcad3; /* Old browsers */
  background-image: linear-gradient(to bottom,  #bdcad3 0%,#8395a2 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */

  @media screen and (min-width: 1024px) {
    height: 100px;
  }
}

.cover-photo {

  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0,0,0,0.4);
  }
}

.project-thumbnail {
  position: absolute;
  bottom: -25px;
  left: 50%;
  margin-left: -20px;
  z-index: 1;

  @media screen and (min-width: 1024px) {
    bottom: -35px;
    margin-left: -25px;
  }
}

.project-body {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  // justify-content: flex-end;
  align-items: center;
  flex: 1 1 auto;
  width: 100%;
  padding-top: 30px;
  text-align: center;
  // height: 100%;

  @media screen and (min-width: 1024px) {
    padding-top: 40px;
  }

  &-top,
  &-bottom {
    width: 100%;
  }
}

.project-position {
  @include fontSize(14px);
  line-height: 1.5;
  order: 2;
  margin: 0 0 3px;
  font-weight: $regular;

  @media screen and (min-width: 1024px) {
    @include fontSize(18px);
    line-height: 1.3;
    margin: 0 0 6px;
  }
}

.project-company-name {
  @include fontSize(12px);
  order: 1;
  line-height: 1;
  font-weight: $medium;
  text-transform: uppercase;
  color: $grey-blue;
  margin: 0;

  @media screen and (min-width: 1024px) {
    @include fontSize(14px);
    margin-bottom: 12px;
  }
}

.project-btn {
  display: none;

  @media screen and (min-width: 1024px) {
    display: inline-block;
    position: relative;
    width: 85%;
    order: 4;
    overflow: hidden;
  }
}

.project-btn-text,
.project-btn-text-hover {
  position: absolute;
  right: 0;
  left: 0;
  padding: 0 10px;
  overflow: hidden;
  white-space: nowrap;
  text-align: center;
  text-overflow: ellipsis;
  transition: transform 0.25s;
}

.project-btn-text {
  transform: translateY(0);
}

.project-btn-text-hover {
  transform: translateY(33px);
}


.progressbar {
  order: 5;
  position: relative;
  width: 100%;
  height: 4px;
  margin: 20px 0 0;
  background: $border-color;

  @media screen and (min-width: 1024px) {
    margin: 20px 0;
    height: 2px;
  }

  .progress {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    background: $green;

    &.delayed {
      background: $red;
    }

    &.status-good {
      background: $green !important;
    }

    &.status-warning {
      background: $warning !important;
    }

    &.status-danger {
      background: $red !important;
    }
  }
}

.project-footer {
  display: none;

  @media screen and (min-width: 1024px) {
    @include fontSize(14px);
    display: block;
    position: relative;
    width: 100%;
    color: $text-grey;
    line-height: 1;
  }
}

.project-due-date {
  white-space: nowrap;
}

.project-due-date,
.project-candidates-quantity,
.project-manager,
.project-files-quantity {
  display: block;
  position: absolute;
  top: 0;
  line-height: 1.3;
  transition: transform 0.3s, opacity 0.2s;
}

.project-due-date,
.project-candidates-quantity {
  left: 0;
}

.project-manager,
.project-files-quantity {
  right: 0;
}

.project-manager {
  width: 60%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: right;
}

.project-due-date,
.project-manager {
  opacity: 1;
  transform: translateY(0);
}

.project-files-quantity,
.project-candidates-quantity {
  opacity: 0;
  transform: translateY(15px);
}

.grid-link {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
  // height: 100%;
  text-decoration: none;
  background: #fff;
  color: $black;
}

.project-link {
  padding: 70px 0 0;

  @media screen and (min-width: 1024px) {
    padding: 100px 20px 35px;

    &:hover {

      .project-btn {
        background: $navigio-blue;
        color: #fff;
      }

      .project-btn-text {
        transform: translateY(-33px);
        // transition: transform 0.3s, opacity 0.2s;
      }

      .project-btn-text-hover {
        transform: translateY(0);
      }

      .project-due-date,
      .project-manager {
        opacity: 0;
        transform: translateY(15px);

      }

      .project-files-quantity,
      .project-candidates-quantity {
        opacity: 1;
        transform: translateY(0);

      }
    }
  }
}

.welcome-message {
  position: relative;
  padding: 35px 50px;
  background: $dark-grey;
  color: #fff;
  text-align: center;

  .btn-close {
    top: 20px;
    right: 20px;
    background: rgba(0, 0, 0, 0.4);

    .close-icon {
      fill: #fff;
    }

    @media screen and (min-width: 1024px) {
      top: 25px;
      right: 40px;
    }
  }
}

.welcome,
.status {
  display: block;
}

.status-link {
  color: #fff;
}

.grid-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 170px;
}

.grid-btn {
  padding: 12px;
  border-top: 1px solid $border-color;
  background: #fff;
  color: $black;
}

.grid-heading {
  @include fontSize(16px);
  font-weight: $regular;
  line-height: 1.4;
  margin: 0;
}
