// Navigio logo

.letters {
  fill: #fff;
}

.navigio-fin {
  opacity: 0.5;
  fill: none;
  stroke: #fff;
  stroke-miterlimit: 3.8637;
}

.aAndo {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #fff;
}
