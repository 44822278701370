.step-indicator {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 200px;
  height: 2px;
  margin: 0 auto 30px;
  background: $border-color;

  &:before {
    content: '';
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    background: $navigio-blue;
  }

  &-1 {

    .step-1 {
      background: $navigio-blue;
    }
  }

  &-2 {

    &:before {
      width: 50%;
    }

    .step-1,
    .step-2 {
      background: $navigio-blue;
    }
  }

  &-3 {

    &:before {
      width: 100%;
    }

    .step-1,
    .step-2,
    .step-3 {
      background: $navigio-blue;
    }
  }
}

.step {
  display: block;
  position: relative;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: $border-color;
  z-index: 1;
}

.step-1 { // in order for this to look ok in IE11, which counts pseudo elements as a flex-item
  order: -1;
}
