.dropdown-more-wrapper {
  position: relative;

  &.open {
    .dropdown-more {
      display: block;
    }

    .ellipsis-btn {
      visibility: visible;
    }
  }
}

.dropdown-more {
  display: none;
  position: absolute;
  top: 100%;
  right: 0;
  box-shadow: 0 1px 8px rgba(0,0,0,.14);
  z-index: 1;
  border-radius: 3px;
  padding: 5px;
  background: #fff;
}

.dropdown-more-btn {
  display: block;
  width: 100%;
  padding: 9px 18px;
  white-space: nowrap;

  @media screen and (min-width: 1024px) {

    &:hover {
      background: $menu-grey;
    }
  }
}
