* {
  box-sizing: border-box;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;

  &:focus {
    outline: none;
  }
}

html {
  height: 100%;
  font-size: 62.5%;
  color: $black;
  background: $grey-base;
}

body {
  height: 100%;
  display: flex;
  flex-direction: column;
  font: 400 1.6rem/1.6 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &.modal-open {
    overflow: hidden;

    .page {
      display: none;

      @media screen and (min-width: 500px) {
        display: flex;
      }
    }
  }
}
