.customer-cover-header {
	background: $dark-grey;
	color:white;
	padding:15px 0;

	.sub-heading {
		opacity: 0.8;
		font-weight:500;
	}
}

.customer-img-placeholder {
	width: 110px;
	height: 110px;
	border-radius: 100%;
	background-color: #8a9eac;
	color: #fff;
	text-align: center;
	cursor: pointer;
	background-size: cover;
	background-position: center center;

	i {
		line-height: 110px;
	}
}
