.thumbnail-row {
  margin: 0;

  .files-table &,
  .todo-thumbnails-wrapper & {
    margin: 0 0 0 6px;

    &:first-child {
      margin-left: 0;
    }
  }
}

.thumbnail {
  width: 100%;

  &-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 0 0 auto;
    overflow: hidden;
    background: $grey-base;
    color: #fff;
    margin: 0;

    &-colored {
      background: #8a9eac;
    }

    img {
      width: 100%;
      height: auto;
    }
  }

  &-circle {
    border-radius: 50%;
  }

  &-border {
    border-width: 3px;
    border-style: solid;
    border-color: #fff;
    border-radius: 3px;

    &-xl {
      border-width: 10px;
      border-style: solid;
      border-color: #fff;
    }
  }

  &-xs {
    width: 32px;
    height: 32px;

    .file-icon {
      width: 14px;
      height: 14px;
    }
  }

  &-sm {
    width: 32px;
    height: 32px;
    line-height: 32px;

    .file-icon {
      width: 14px;
      height: 14px;
    }

    @media screen and (min-width: 1200px) {
      width: 42px;
      height: 42px;
      line-height: 42px;

      .file-icon {
        width: 18px;
        height: 18px;
      }
    }
  }

  &-md {
    width: 40px;
    height: 40px;

    @media screen and (min-width: 1024px) {
      width: 50px;
      height: 50px;
    }
  }

  &-lg {
    width: 70px;
    height: 70px;

    @media screen and (min-width: 1024px) {
      width: 100px;
      height: 100px;
    }
  }

  &-xl {
    width: 100px;
    height: 100px;

    @media screen and (min-width: 1024px) {
      width: 160px;
      height: 160px;
    }
  }

  &-mb {
    margin-bottom: 10px;
  }
  // &-xs-row {
  //   margin: 0 6px 0 0;
  // }
  //
  // &-sm-row {
  //   margin: 0 12px 0 0;
  // }
  //
  // &-md-col {
  //   margin: 0 0 6px;
  // }
  //
  // &-lg-col {
  //   margin: 0 0 10px;
  // }
}

.thumbnail-initials,
.thumbnail-more {
  text-transform: uppercase;
  letter-spacing: 1px;

  .thumbnail-xs & {
    @include fontSize(13px);
  }

  .thumbnail-sm & {
    @include fontSize(13px);

    @media screen and (min-width: 1200px) {
      @include fontSize(16px);
    }
  }

  .thumbnail-lg & {
    @include fontSize(22px);

    @media screen and (min-width: 1024px) {
      @include fontSize(28px);
    }
  }

  .thumbnail-xl & {
    @include fontSize(28px);

    @media screen and (min-width: 1024px) {
      @include fontSize(42px);
    }
  }
}

.thumbnail-fixed {
  display: inline-block;

  @media screen and (min-width: 1024px) {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.thumbnail-tooltip {
  position: relative;

  @media screen and (min-width: 1024px) {
    &:hover {
      .tooltip {
        visibility: visible;
        opacity: 1;
      }
    }
  }
}

.tooltip {
  @include fontSize(12px);
  white-space: nowrap;

  @media screen and (min-width: 1024px) {
    display: inline-block;
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-bottom: 8px;
    transform: translateX(-50%);
    padding: 5px;
    background: $black;
    color: #fff;
    border-radius: 3px;
    visibility: hidden;
    opacity: 0;
    transition: visibility 300ms ease-in-out, opacity 300ms ease-in-out;

    &:after {
      content: '';
      position: absolute;
      top: 100%;
      left: 50%;
      width: 0;
      height: 0;
      margin-left: -2px;

      border-right: 4px solid transparent;
      border-left: 4px solid transparent;

      border-top: 4px solid $black;
    }

  }
}
