@media print {
  * {
    background: transparent !important;
    color: #000 !important;
    box-shadow: none !important;
    text-shadow: none !important;
  }
  a,
  a:visited {
    text-decoration: underline;
  }
  a[href]:after {
    content: " (" attr(href) ")";
  }
  abbr[title]:after {
    content: " (" attr(title) ")";
  }
  .ir a:after,
   a[href^="javascript:"]:after,
   a[href^="#"]:after {
    content: "";
  }
  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid;
  }
  thead {
    display: table-header-group;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  img {
    max-width: 100% !important;
  }
  @page {
    margin: 2cm 1.8cm 1.5cm;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }

  .btn,
  button,
  .question-heading-question {
    display: none;
  }

  .letters,
  .aAndo {
    fill: $navigio-blue;
  }

  .navigio-fin {
    stroke: $navigio-blue;
  }

  .progress-circle-text {
    fill: #000;
  }

  .page-break-avoid {
    page-break-inside: avoid;
  }

  .page-break-before-always {
    page-break-before: always;
  }

  .page-break-after-always {
    page-break-after: always;
  }

  .text-left-print {
    text-align: left;
  }

  .survey-results-wrapper .survey-content-wrapper {
    padding: 0;
  }

  .survey-results-header:before {
    content: none;
  }

  .two-col-chart {
  	flex: 0 0 auto;
    width: 48%;

  	&:nth-child(odd) {
      padding-right: 2%;
      clear: left;
  	}

  	&:nth-child(even) {
  		padding-left: 2%;
  	}
  }

  .height-2bars {
  	height: 80px;
  }

  .height-2bars-noXvalues {
    height: 80px - 25px;
  }

  .two-col-chart-sm {
  	min-height: 160px;
  }

  .two-col-chart-sm-noXvalues {
  	min-height: 160px - 25px;
  }

  .answer-others,
  .answer-mine {
    border-width: 10px;
  }

  .answer-others {
  	border-color: $navigio-blue;
  }

  .answer-mine {
  	border-color: $orange;
  }

//Typography
  .survey-results-heading-lg {
    font-size: 18pt;
    line-height: 20pt;
    text-align: left;
  }

  .survey-results-heading-lg-center {
    text-align: center;
  }
  .responserate-wrapper{
    margin-left: 0;
  }

  .survey-results-heading-md {
    font-size: 15pt;
    line-height: 16pt;
    text-align: left;
  }

  .results-intro {
    text-align: left !important;
  }

  .chart-heading-lg {
    font-size: 14pt;
    line-height: 16pt;
    margin-bottom: 1.8rem;
  }

  .chart-heading {
    font-size: 13pt;
    line-height: 16pt;
    min-height: 85px;
  }

  .text-left-print {
    text-align: left;
  }

  .comments-answers {
    font-size: 12pt;
    line-height: 13pt;
  }

  .title-sub-results {
    font-size: 9pt;
    line-height: 11pt;
  }
  //margins
  .print-p-0 {
    padding: 0 !important;
  }
  .print-mb-0 {
    margin-bottom: 0 !important;
  }

  .print-mb-10 {
    margin-bottom: 1rem !important;
  }

  .print-mb-30 {
    margin-bottom: 3rem !important;
  }

  .print-mb-50 {
    margin-bottom: 5rem !important;
  }
}
