.status-indicator-sleeve {
  position: relative;
  margin-left: 15px;

}

.status-indicator-wrapper {
  position: absolute;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  margin-top: -15px;
  background: transparent;

  overflow: hidden;
  transition: height 150ms ease-in-out, background 300ms, box-shadow 300ms;

  &-top {
    top: 50%;
    height: 30px;
    border-radius: 30px;

    &:hover {
      background: $dark-blue;
    }

    &.open {
      height: 115px;
      background: $dark-blue;
      box-shadow: 0 1px 2px #0061a2;
    }
  }

  &-grid {
    top: 17px;
    left: 2px;
    height: 35px;
    border-radius: 35px;

    @media screen and (min-width: 1024px) {
      top: 13px;
      left: 0px;
    }

    &:hover {
      background: transparentize($darker-grey, 0.2);
    }

    &.open {
      height: 135px;
      background: transparentize($darker-grey, 0.2);
      box-shadow: 0 1px 2px transparentize($darker-grey, 0.8);
    }
  }
}

.status-indicator-btn {
  padding: 5px;
  flex: 0 0 auto;
}

.status-indicators {
  display: flex;
  flex-direction: column;
  flex: 0 0 auto;
  height: 90px;

  .status-indicator-wrapper-grid & {
    height: 90px;
  }

  .status-indicator-btn {
    padding-top: 0;
  }
}

.status-indicator {
  @extend .notifications-indicator;
  min-width: 20px;
  height: 20px;
  cursor: pointer;
  transition: background 300ms;

  .status-indicator-wrapper-grid & {
    min-width: 25px;
    height: 25px;
  }
}
