@mixin clear-list () {
  margin: 0;
  padding: 0;
  list-style: none;
}

@function calculateRem($size) {
  $remSize: $size / 10px;
  @return #{$remSize}rem;
}

@mixin fontSize($size) {
  font-size: $size; //Fallback in px
  font-size: calculateRem($size);
}

@mixin placeholder-color($color) {

  &::-webkit-input-placeholder {
    color: $color;
    opacity: 1;
  }

  &:-moz-placeholder {
    color: $color;
    opacity: 1;
  }

  &::-moz-placeholder {
    color: $color;
    opacity: 1;
  }

  &:-ms-input-placeholder {
    color: $color;
    opacity: 1;
  }
}
