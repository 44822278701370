$regular: 400;
$medium: 500;
$bold: 700;

.italic {
  font-style: italic;
}

.bold {
  font-weight: $bold;
}

.link-primary {
  color: $navigio-blue;
}

.line-height-1 {
  line-height: 1;
}

.link-light {
  color: #fff;
  text-decoration: underline;

  &:active {
    color: #fff;
  }
}

 .no-decoration {
  text-decoration: none;
 }

.link-danger {
  color: $red;
}

.link-no-style {
  color: $black;
  text-decoration: none;
}

.page-header-heading {
  @include fontSize(20px);
  display: flex;
  align-items: center;
  font-weight: $medium;
  margin: 0;
  color: #fff;

  @media screen and (min-width: 1024px) {
    @include fontSize(24px);
  }
}

.page-header-client {
  display: block;
  white-space: nowrap;
  overflow: auto;
  margin-right: 20px;
  font-weight: $regular;
  color: $baby-blue;
}

.page-header-name {
  display: block;
  white-space: nowrap;
  overflow: auto;
}

.heading-md {
  @include fontSize(20px);
  font-weight: $regular;
  margin: 0 0 1em;

  @media screen and (min-width: 1024px) {
    @include fontSize(28px);
  }
}

.heading-normalized {
  @include fontSize(16px);
  font-weight: $regular;
  margin: 0;
}

.text-xs {
  @include fontSize(12px);
}

.text-sm {
  @include fontSize(14px);
}

.text-md {
  @include fontSize(18px);
}

.text-lg {
  @include fontSize(28px);
}

.text-regular {
  font-weight: $regular;
}

.text-medium {
  font-weight: $medium;
}

.text-centered {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-uppercase {
  text-transform: uppercase;
}

.user-name {
  @include fontSize(18px);
  font-weight: $regular;
  line-height: 1;
  margin: 0;

  @media screen and (min-width: 1024px) {
    @include fontSize(24px);
  }
}


.title {
  font-weight: $regular;
}

.title-sm {
  @include fontSize(14px);
  @extend .title;
}

.title-md {
  @include fontSize(16px);
  @extend .title;
}

.title-lg {
  @include fontSize(16px);
  @extend .title;

  @media screen and (min-width: 1024px) {
    @include fontSize(18px);
  }
}

.title-xl {
  @include fontSize(24px);
  @extend .title;
}

.title-light {
  color: #fff;
}

.title-dark {
  color: $black;
}

.title-sub {
  font-weight: $medium;
  text-transform: uppercase;
}

.title-sub-sm {
  @include fontSize(12px);
  @extend .title-sub;
}

.title-sub-md {
  @include fontSize(12px);
  @extend .title-sub;
  line-height: 1.3;

  @media screen and (min-width: 1024px) {
    @include fontSize(14px);
  }
}

.title-sub-results {
  @extend .title-sub;
  @extend .title-sm;
}

.title-sub-light {
  color: $text-grey;
}

.title-sub-truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.title-sub-dark {
  color: $grey-blue;
}

.clear-heading {
  margin: 0;
}

.heading-secondary {
  @include fontSize(20px);
  @extend .title;
  margin: 0 0 1em;
}
