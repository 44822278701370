.header-menu,
.projects-nav {
  @include clear-list();
}

/*********************
  Header navigation
**********************/

.nav-main {
  position: absolute;
  top: 70px;
  right: 0;
  left: 0;
  max-height: 0;
  overflow: hidden;
  transition: all 300ms;
  visibility: hidden;
  z-index: 10;

  &.open {
    visibility: visible;
    max-height: 470px;
    transition: visibility 0.3s, max-height 0.3s;
  }

  @media screen and (min-width: 1024px) {
    @include fontSize(18px);
    top: 50px;
    right: auto;
    width: 260px;
    max-height: none;
    box-shadow: 1px 1px 68px rgba(0, 0, 0, 0.19);
    padding: 15px;
    background: #fff;
    border-radius: 3px;
    opacity: 0;
    transition: visibility 0.15s, opacity 0.15s;

    &.open {
      opacity: 1;
    }
  }
}

.nav-icons {
  display: flex;
  flex: 1 0 auto;
  justify-content: space-between;
  padding: 0 15px 10px;

  @media screen and (min-width: 420px) {
    justify-content: flex-start;
  }

  @media screen and (min-width: 1024px) {
    padding: 0;
  }
}

.header-menu-shortcut {
  display: flex;
  justify-content: space-around;
}

.menu-btn-wrapper {
  display: flex;

  @media screen and (min-width: 1024px) {
    margin-right: 25px;
  }
}

.menu-btn {
  @extend .clear-btn;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  text-align: center;
  text-decoration: none;
  cursor: pointer;

  &.open {
    background: #3490cc;
  }

  @media screen and (min-width: 1024px) {
    width: 25px;
    height: 25px;

    &.open {
      background: none;
    }
  }
}

.header-shortcut-link {
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  margin: 0 12px;

  @media screen and (min-width: 1024px) {
    width: 25px;
    height: 25px;

    &.open {
      background: none;
    }
  }

  &:first-of-type {
    margin-left: 24px;
  }

  &.open,
  &.active {
    background: #3490cc;
  }

  .fa {
    color: #fff;
  }

  .fa-th-large,
  .fa-file {
    @include fontSize(18px);
  }
}

.header-menu-link {
  display: block;
  padding: 13px 15px 12px;
  text-decoration: none;
  transition: background 0.2s ease-in-out;

  @media screen and (min-width: 1024px) {

    &:hover {
      background: $menu-grey;
    }

    &.active {
      color: $navigio-blue;
    }
  }
}

.header-menu-profile-link {
  color: $black;
  background: #fff;
}

.header-menu-main-link {
  padding: 20px 17px;
  color: #fff;
  background: $menu-blue;
  border-bottom: 1px solid #156da7;

  @media screen and (min-width: 1024px) {
    padding: 13px 15px 12px;
    color: $black;
    background: #fff;
    border: none;
  }
}


/*****************************
  Subnavigation for projects
******************************/
$navigation-heightSm: 38px;
$navigation-height: 45px;

.projects-nav {
  display: flex;
  height: $navigation-heightSm;
  background: $dark-blue;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }

  @media screen and (min-width: 1024px) {
    height: $navigation-height;
    padding: 0 25px;
    background: $navigio-blue;
  }
}

.projects-nav-item {
  display: flex;
  flex-direction: column;
  flex: 0 0 auto;
}

.projects-nav-text {
  position: relative;
  flex: 1 1 auto;
  height: 100%;
  margin: 0 15px;
  font-weight: $medium;
  color: #fff;
  cursor: pointer;

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 3px;
  }

  .projects-nav-item.active & {
   
    color: $orange;

  }

  @media screen and (min-width: 1024px) {
    @include fontSize(18px);
    margin: 0 25px;
    line-height: normal;
  }
}

.sub-menu-link {
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 50%;
  text-align: center;
  text-decoration: none;
  cursor: pointer;

  @media screen and (min-width: 1024px) {

    &.open {
      background: none;
    }
  }


  &.open,
  &.active {
    background: #3490cc;
  }

  .fa {
    color: #fff;
  }

  .fa-th-large,
  .fa-file {
    @include fontSize(18px);
  }
}
