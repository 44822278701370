.search-header {
  padding: 2rem 15px 0;

  @media screen and (min-width: 1024px) {
    padding: 4rem 0 0;
  }
}

.search-page-search {
  background: none;

  @media screen and (min-width: 1024px) {
    display: none;
  }
}

.search-content {
  background: #fff;

  @media screen and (min-width: 1024px) {
    background: none;
  }
}
