
// Grid view
.projects-grid {
  padding-top: 63px;
  position: relative;

  .toggle-all {
    top: 10px;
    right: 40px;
  }

  .projects-list {
    display: flex;
    flex-wrap: wrap;

    .projects-list-item {
      position: relative;
      display: flex;
      flex-direction: column;

      flex: 0 0 auto;
      width: 50%;
      border-width: 1px 1px 0 0;
      border-style: solid;
      border-color: $border-color;

      &:nth-child(even) {
        border-width: 1px 0 0;
      }

      @media screen and (min-width: 1024px) {
        flex: 0 1 auto;
        width: 33.33%;
        padding: 15px;
        border: none;
      }

      @media screen and (min-width: 1200px) {
        width: 25%;
      }
    }

    .project {
      position: relative;
      box-shadow: 0 1px 2px rgba(0,0,0,.14);
      transition: transform 0.3s, box-shadow 0.3s;

      @media screen and (min-width: 1024px) {

        &:hover {
          transform: translateY(-3px);
          box-shadow: 0 3px 6px rgba(0,0,0,.2);

          .more-btn {
            opacity: 1;
          }
        }
      }

      // .status-indicator-wrapper {
      //   position: absolute;
      //   top: 8px;
      //   left: 8px;
      //
      //   @media screen and (min-width: 1024px) {
      //     top: -12px;
      //     right: -9px;
      //     left: auto;
      //   }
      // }

      .more-btn {
        position: absolute;
        top: 0;
        right: 0;

        @media screen and (min-width: 1024px) {
          opacity: 0;
        }
      }
    }
  }
}

.toggle-all-overview-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  display: flex;
  justify-content: center;
  padding: 15px 0;

  @media screen and (min-width: 1024px) {
    justify-content: flex-end;
    padding-right: 25px;
  }

  @media screen and (min-width: 1200px) {
    padding-right: 65px;
  }
}

.toggle-all-overview {
  @extend .btn;
  @extend .btn-sm;
  @extend .btn-rounded;
  @extend .btn-neutral-glam;
  text-decoration: none;
  display: inline;
  margin-right: 10px;
  @media screen and (min-width: 1024px) {
    height: auto;
    padding: 0;
    line-height: inherit;
    background: none;
    text-decoration: underline;
    border-radius: 0;
  }
  @media screen and (max-width: 370px) {
    margin-right: 3px;
    padding: 0 5px;
    height: auto;
  }
}
// If browser supports css grid
// @supports grid works in Edge, but Edge uses the old syntax for grid
// @supports (display: grid) {
//
//   @media screen and (min-width: 1024px) {
//     .projects-grid .projects-list {
//       display: grid;
//       grid-template-columns: repeat(auto-fill, minmax(300px, auto));
//
//       .projects-list-item {
//         width: auto;
//       }
//     }
//   }
// }

// If candidate been rejected
.projects-list-item.rejected {
  .grid-body {
    opacity: 0.3;
  }
}
